import React from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import DetailsTable from '../table/DetailsTable'
import Map from '../dashboard/Map'
import './SiteDetails.css'

function SiteDetails(props) {
    const { site, auth, siteId, secondary, url } = props;
    if (site) {
        return (
            <div className="row">
                <div className="site-details white col s12 m9">
                    <h4 className="site-title">{site.title} <b>/</b> All Data</h4>
                    <div>
                        <DetailsTable site={site} auth={auth} siteId={siteId} secondary={secondary} url={url} />
                    </div>
                </div>
                <div className="col s0 m3">
                    <Map />
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="container center">
                <p>Loading site...</p>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const url = ownProps.match.url;
    const sites = state.firestore.data.sites;
    const secondarySites = state.firestore.data.secondarySites;
    let site = null;
    let secondary = false;
    if( sites && sites[id]) {
        site = sites[id];
    }
    if(secondarySites && secondarySites[id]) {
        site = secondarySites[id];
        secondary = true;
    }
    return {
        siteId: id,
        url: url,
        site: site,
        auth: state.firebase.auth,
        secondary
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect(props => [
        { collection: 'sites', orderBy: 'title', storeAs: 'sites' },
        { collection: 'secondarySites', orderBy: 'title', storeAs: 'secondarySites' }
    ])
)(SiteDetails)
