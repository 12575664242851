const initState = {
    authError: null,
    adminError: null,
    isAdmin: false
}

const authReducer = (state = initState, action) => {
    switch(action.type){
        case 'LOGIN_ERROR':
            console.log('Login Error' , action.err.message);
            return {
                ...state,
                authError: 'Login Failed'
            }
        case 'LOGIN_SUCCESS':
            console.log('Login Success');
            return {
                ...state,
                authError: null
            }
        case 'SIGNOUT_SUCCESS':
            console.log('Signout Success');
            return {
                ...state,
                authError: null,
                isAdmin: false,
                checkAdminError: null
            }
        case 'SIGNUP_SUCCESS':
            console.log('Signup Success');
            return {
                ...state,
                authError: null
            }
        case 'SIGNUP_ERROR':
            console.log('Signup Error');
            return {
                ...state,
                authError: action.err.message
            }
        case 'ADDADMIN_SUCCESS':
            console.log(action.result);
            return {
                ...state,
                adminError: null
            }
        case 'ADDADMIN_ERROR':
            console.log(action.err.message);
            return {
                ...state,
                adminError: action.err.message
            }
        case 'IS_ADMIN_SUCCESS':
            // console.log(`User is admin: ${action.isAdmin}`);
            return {
                ...state,
                isAdmin: true,
                checkAdminError: null
            }
        case 'IS_ADMIN_FAILURE':
            // console.log(`User is not admin: ${action.isAdmin}`);
            return {
                ...state,
                isAdmin: false,
                checkAdminError: null
            }
        case 'IS_ADMIN_ERROR':
            console.log(action.err.message);
            return {
                ...state,
                isAdmin: false,
                checkAdminError: action.err.message
            }
        default:
            return state;
    }
}

export default authReducer