import React from 'react'
import { connect } from 'react-redux'
import { deleteSpecificDateData } from '../../../store/actions/siteActions'
import './DeleteAdditionalInfo.styles.scss'

function DeleteAdditionalInfo(props) {
    const { siteId, stringDate, data, deleteSpecificDateData, secondary } = props;
    return (
        <td>
            <div className="delete-additional">
                <i className="material-icons delete-button-additional" onClick={() => deleteSpecificDateData(siteId, stringDate, data, secondary)}>delete</i>
            </div>
        </td>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteSpecificDateData: (siteId, stringDate, data, secondary) => dispatch(deleteSpecificDateData(siteId, stringDate, data, secondary))
    }
}


export default connect(null, mapDispatchToProps)(DeleteAdditionalInfo)
