import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { addHyper } from '../../store/actions/siteActions'
import PopupImage from './PopupImage/PopupImage.component'
import DeleteButton from './DeleteButton/DeleteButton.component'
import Map from '../dashboard/Map'
import * as URI from 'uri-js'
import './tables.scss'

class HyperTable extends Component {
    state = {
        date: '',
        sensor: '',
        boundingPolygon: '',
        name: '',
        fileLink: '',
        imageLink: '',
        metaName: '',
        metaLink: '',
        isMetaLink: false,
        kmzName: '',
        kmzLink: '',
        kmzDescription: '',
        hyperExtraInfo: ''
    }
    checkUrls = (urlArray) => {
        return urlArray.map(url => {
            if (URI.parse(url).scheme == null) {
                return 'https://' + url;
            }
            return url;
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let {isMetaLink, ...hyper} = this.state;
        const { secondary } = this.props;

        let checkboxChecked = document.getElementById("extra-data").checked;

        const urlsToCheck = isMetaLink ? [hyper.fileLink, hyper.imageLink, hyper.kmzLink, hyper.metaLink] :
            [hyper.fileLink, hyper.imageLink, hyper.kmzLink];
        const fixedURLS = this.checkUrls(urlsToCheck);
        hyper.fileLink = fixedURLS[0];
        hyper.imageLink = fixedURLS[1];
        hyper.kmzLink = fixedURLS[2];
        if (isMetaLink) {
            hyper.metaLink = fixedURLS[3];
        }

        hyper.date = hyper.date.slice(0, 4) + '-' + hyper.date.slice(4, 6) + '-' + hyper.date.slice(6);
        this.props.addHyper(this.props.siteId, hyper, checkboxChecked, isMetaLink, secondary);
        let hyperOnlyElems = document.getElementsByClassName("hyper-only");
        let extraInfoElem = document.getElementById("hyper-extra-info");
        for (let i = 0; i < hyperOnlyElems.length; i++) {
            hyperOnlyElems[i].style.display = "block";
        }
        extraInfoElem.style.display = "none";

        e.target.reset();
    }
    toggleExtraData = () => {
        let checkboxChecked = document.getElementById("extra-data").checked;
        let hyperOnlyElems = document.getElementsByClassName("hyper-only");
        let extraInfoElem = document.getElementById("hyper-extra-info");
        for (let i = 0; i < hyperOnlyElems.length; i++) {
            if (checkboxChecked) {
                hyperOnlyElems[i].style.display = "none";
                extraInfoElem.style.display = "block";
            }
            else {
                hyperOnlyElems[i].style.display = "block";
                extraInfoElem.style.display = "none";
            }
        }
    }
    toggleMetaLink = () => {
        this.setState({
            ...this.state,
            isMetaLink: !this.state.isMetaLink
        })
    }

    isURL = (str) => {
        const pattern = new RegExp('^(https:\\/\\/)'); // fragment locator
        return !!pattern.test(str);
    }

    render() {
        const { auth, site, siteId, hyper, isAdmin, secondary } = this.props;
        let dateString, removedDayString;
        // if (!auth.uid) return <Redirect to='/signin' />;
        if (site) {
            var hyperElems = [];
            for (var hyperKey in hyper) {
                if (hyper.hasOwnProperty(hyperKey) && hyper[hyperKey]) {
                    dateString = hyper[hyperKey].date.toDate().toDateString();
                    removedDayString = dateString.slice(dateString.indexOf(" ") + 1);
                    const metaData = hyper[hyperKey].metaLink;
                    if(auth.uid) {
                        hyperElems.push((
                            <tr key={hyperKey}>
                                <td>{removedDayString}</td>
                                <td>{hyper[hyperKey].sensor}</td>
                                <td>{hyper[hyperKey].fileLink !== 'https://' ? <a href={hyper[hyperKey].fileLink} target="_blank" rel="noopener noreferrer">{hyper[hyperKey].name}</a> : '- - -'}</td>
                                <td>{hyper[hyperKey].boundingPolygon}</td>
                                <td>{this.isURL(metaData) ? <a href={hyper[hyperKey].metaLink} target="_blank" rel="noopener noreferrer">Meta Link</a> : '- - -'}</td>
                                <td>{hyper[hyperKey].kmzLink !== 'https://' ? <a href={hyper[hyperKey].kmzLink} target="_blank" rel="noopener noreferrer">KMZ Link</a> : '- - -'}</td>
                                {isAdmin ? <DeleteButton siteId={siteId} id={hyperKey} data={hyper[hyperKey]} dataType="hyper" secondary={secondary} /> : null}
                            </tr>
                        ));
                    }
                    else {
                        hyperElems.push((
                            <tr key={hyperKey}>
                                <td>{removedDayString}</td>
                                <td>{hyper[hyperKey].sensor}</td>
                                <td><PopupImage imageUrl={hyper[hyperKey].imageLink} name={hyper[hyperKey].name} tableClass="hyper-table"/></td>
                                <td>{hyper[hyperKey].boundingPolygon}</td>
                                <td>{this.isURL(metaData) ? <a href={hyper[hyperKey].metaLink} target="_blank" rel="noopener noreferrer">Meta Link</a> : '- - -'}</td>
                                <td>{hyper[hyperKey].kmzLink !== 'https://' ? <a href={hyper[hyperKey].kmzLink} target="_blank" rel="noopener noreferrer">KMZ Link</a> : '- - -'}</td>
                            </tr>
                        ));
                    }
                }
            }
            return (
                <div className="hyper-table row">
                    <div className="col s12 m9 hyper-table-inside">
                        <div className="white data-table">
                            <h4>{site ? site.title : null} / Drone & Ultralight Images</h4>
                            <table className="striped highlight responsive-table">
                                <thead>
                                    <tr>
                                    <th>Date</th>
                                    <th>Sensor/Camera</th>
                                    <th>File Link</th>
                                    <th>Bounding<br/>Polygon</th>
                                    <th>Meta Link</th>
                                    <th>KMZ Link</th>
                                    {(auth.uid && isAdmin) ? <th>Delete Hyper-Spectra</th> : null}
                                    </tr>
                                </thead>

                                <tbody>
                                    {hyperElems && hyperElems.map(hyper => {
                                        return hyper;
                                    })}
                                </tbody>
                            </table>
                            {auth.uid && isAdmin ? (
                                <form className="white z-depth-2" onSubmit={this.handleSubmit}>
                                    <h5 className="grey-text text-darken-3">Add a new Hyper-Spectra:</h5>
                                    <div className="input-field">
                                        <input type="text" id='date' onChange={this.handleChange} />
                                        <label htmlFor="date">Date</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id='sensor' onChange={this.handleChange} />
                                        <label htmlFor="sensor">Sensor</label>
                                    </div>
                                    <div className="input-field hyper-only">
                                        <input type="text" id='boundingPolygon' onChange={this.handleChange} />
                                        <label htmlFor="boundingPolygon">Bounding Polygon</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id='name' onChange={this.handleChange} />
                                        <label htmlFor="name">File Name</label>
                                    </div>
                                    <div className="input-field">
                                        <textarea id="fileLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="fileLink">File Link</label>
                                    </div>
                                    <div className="input-field hyper-only">
                                        <textarea id="imageLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="imageLink">Quick-Look Image Link (Direct link to image)</label>
                                    </div>
                                    <div className="row">
                                        <div className="input-field hyper-only col s6">
                                            <textarea id="metaName" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="metaName">Meta {this.state.isMetaLink ? "Link Name" : "Information"}</label>
                                        </div>
                                        {this.state.isMetaLink ? (
                                            <div className="input-field hyper-only col s6">
                                                <textarea id="metaLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                                <label htmlFor="metaLink">Meta Link</label>
                                            </div>
                                        ) : null}
                                        <div className="hyper-only col s6">
                                            <label htmlFor="isMetaLink">
                                                <div className="row"></div>
                                                <div className="row"></div>
                                                <div className="row">
                                                    <input type="checkbox" id="isMetaLink" onClick={this.toggleMetaLink} />
                                                    <span>Is Meta Information a link?</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field hyper-only col s4">
                                            <textarea id="kmzName" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzName">KMZ Link Name</label>
                                        </div>
                                        <div className="input-field hyper-only col s4">
                                            <textarea id="kmzLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzLink">KMZ Link</label>
                                        </div>
                                        <div className="input-field hyper-only col s4">
                                            <textarea id="kmzDescription" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzDescription">KMZ Description</label>
                                        </div>
                                    </div>
                                    <div className="input-field" id="hyper-extra-info" style={{ display: "none" }}>
                                        <textarea id="hyperExtraInfo" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="hyperExtraInfo">Info Text</label>
                                    </div>
                                    <label htmlFor="extra-data">
                                        <input type="checkbox" id="extra-data" onClick={this.toggleExtraData} />
                                        <span>Additional data for specific date (Not a hyper-spectra)</span>
                                    </label>
                                    <div className="input-field">
                                        <button className="btn indigo accent-1">Add</button>
                                    </div>
                                </form>
                            ) : null }
                        </div>
                    </div>
                    <div className="col s12 m3 side-map">
                        <Map />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="container center">
                    <p>Loading site...</p>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const sites = state.firestore.data.sites;
    const secondarySites = state.firestore.data.secondarySites;
    let site = null;
    let secondary = false;
    const hyper = state.firestore.data[id + '-hyper'];
    const isAdmin = state.auth.isAdmin;
    if (sites && sites[id]) {
        site = sites[id];
    }
    if (secondarySites && secondarySites[id]) {
        site = secondarySites[id];
        secondary = true;
    }
    return {
        siteId: id,
        site,
        hyper,
        auth: state.firebase.auth,
        isAdmin,
        secondary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // The site id is inside the spectra variable.
        addHyper: (siteId, hyper, isExtraData, isMetaLink, secondary) => dispatch(addHyper(siteId, hyper, isExtraData, isMetaLink, secondary))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        const secondary = props.secondary ? 'econdaryS' : '';
        return [
            {
                collection: `s${secondary}ites`,
                doc: props.match.params.id,
                subcollections: [{ collection: 'hyper' }],
                storeAs: props.match.params.id + '-hyper',
                orderBy: [['date', 'desc'], ['sensor', 'asc'], ['name', 'asc']]
            }
        ]   
    })
)(HyperTable)