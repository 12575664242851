import React, { Component } from 'react'
import { connect } from 'react-redux'
import { makeAdmin } from '../../store/actions/adminActions'
import { Redirect } from 'react-router-dom'
import './UserProfile.css'

class UserProfile extends Component {
    state = {
        email: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.makeAdmin(this.state.email);
    }
    render() {
        const {adminError, isAdmin, auth} = this.props;
        if (!auth.uid) return <Redirect to='/' />;
        // this.props.checkIfAdmin(auth.uid);
        const adminForm = (<form onSubmit={this.handleSubmit}>
                        <h5 className="grey-text text-darken-3">Make Admin</h5>
                        <div className="input-field">
                            <label htmlFor="email">Email</label>
                            <input type="email" id="email" onChange={this.handleChange} required />
                        </div>
                        <div className="input-field">
                            <button className="btn z-depth-0 indigo accent-1">Make Admin</button>
                            <div className="center red-text">
                                {adminError ? <p>{adminError}</p> : null}
                            </div>
                        </div>
                    </form>);
        return (
            <div id="user-profile" className="container white">
                <h2 className="center">User Profile<br /><span className="red-text">{ isAdmin ? 'Admin' : 'Regular User' }</span></h2>
                <div className="center">{this.props.profile.firstName ? `User Name: ${this.props.profile.firstName} ${this.props.profile.lastName}` : null}</div>
                <div className="row">
                    <div className="col s6">{isAdmin ? ((auth.uid ==='nWpAqCvMyFcyUR267rg6DYEKjJh2') ? adminForm : null) : null}</div>
                    <div className="col s6"></div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth,
        adminError: state.auth.adminError,
        isAdmin: state.auth.isAdmin
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        makeAdmin: (email) => dispatch(makeAdmin(email))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile)