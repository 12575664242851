import React, { Component } from 'react'
import PdfFile from '../PdfFile/PdfFile.component'

class Disclaimer extends Component {
    state = {

    }

    componentDidMount() {
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    getWindowDimensions = () => {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height
        };
    }

    handleResize = () => {
        const { width, height } = this.getWindowDimensions();

        this.setState({
            width,
            height
        }, () => console.log(this.state));
    }

    render() {
        let { width } = this.state;

        if (width === undefined) {
            width = this.getWindowDimensions().width;
        }

        return (
            <div>
                <PdfFile pdfSource={width <= 600 ? "/disclaimer_long.pdf" : "./disclaimer.pdf"} mobileView={width <= 600} />
            </div>
        )
    }
}

export default Disclaimer
