import React from 'react'
import { connect } from 'react-redux'
import { deleteRow } from '../../../store/actions/siteActions'
import './DeleteButton.styles.scss'

function DeleteButton(props) {
    const { siteId, id, data, dataType, deleteRow, secondary } = props;
    return (
        <td className="delete-button">
            <i className="material-icons delete-button-icon" onClick={() => deleteRow(siteId, id, data, dataType, secondary)}>delete</i>
        </td>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        deleteRow: (siteId, id, data, dataType, secondary) => dispatch(deleteRow(siteId, id, data, dataType, secondary))
    }
}


export default connect(null,mapDispatchToProps)(DeleteButton)
