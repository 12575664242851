import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Navbar from "./components/layout/Navbar"
import Dashboard from './components/dashboard/Dashboard'
import SiteDetails from './components/site/SiteDetails'
import SignIn from './components/auth/SignIn'
import SignUp from './components/auth/SignUp'
import CreateSite from './components/site/createSite'
import UserProfile from './components/auth/UserProfile'
import SideNavbar from './components/layout/SideNavbar'
import SpectraTable from './components/table/SpectraTable'
import MultiTable from './components/table/MultiTable'
import HyperTable from './components/table/HyperTable'
import RgbTable from './components/table/RgbTable'
import MainTable from './components/table/MainTable/MainTable.component'
import Gradient from './components/documents/Gradient/Gradient.component'
import SiteDescriptions from './components/documents/SiteDescriptions/SiteDescriptions.component'
import Sensors from './components/documents/Sensors/Sensors.component'
import Disclaimer from './components/documents/Disclaimer/Disclaimer.component'
import NotFoundPage from './components/NotFoundPage/NotFoundPage.component'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons'

// import { pdfjs } from 'react-pdf'
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function App() {
  library.add(fas);
  library.add(far);
  return (
    <Router>
      <div className="App">
        <Navbar />
        <SideNavbar />
        <main className="main-comp">
          <Switch>
            <Route exact path="/" component={Dashboard} />
            <Route exact path="/site/:id" component={SiteDetails} />
            <Route path="/site/:id/spectra" component={SpectraTable} />
            <Route path="/site/:id/multi" component={MultiTable} />
            <Route path="/site/:id/hyper" component={HyperTable} />
            <Route path="/site/:id/rgb" component={RgbTable} />
            <Route path="/site/:id/:date" component={MainTable} />
            <Route path="/signin" component={SignIn} />
            <Route path="/signup" component={SignUp} />
            <Route path="/add-site" component={CreateSite} />
            <Route path="/profile" component={UserProfile} />
            <Route path="/gradient" component={Gradient} />
            <Route path="/sensors" component={Sensors} />
            <Route path="/site-descriptions" component={SiteDescriptions} />
            <Route path="/disclaimer" component={Disclaimer} />
            <Route component={NotFoundPage} />
          </Switch>
        </main>
      </div>
    </Router>
  );
}

export default App;