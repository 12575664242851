import firebase from 'firebase/app'
import 'firebase/auth'


export const signIn = (credentials) => {
    return (dispatch, getState) => {
        firebase.auth().signInWithEmailAndPassword(
            credentials.email,
            credentials.password
        ).then((userCredential) => {
            dispatch({ type: 'LOGIN_SUCCESS', payload: userCredential })
        } ).catch( (err) => {
            const signinErrorElem = document.getElementById('signin-error');
            signinErrorElem.classList.toggle('error');
            signinErrorElem.innerText = 'Login Failed'
            dispatch({ type: 'LOGIN_ERROR', err })
        } );
    }
}

export const signOut = () => {
    return (dispatch, getState) => {
        firebase.auth().signOut().then(() => {
            firebase.logout();
            dispatch({ type: 'SIGNOUT_SUCCESS' });
        })
        .catch( err => {
            dispatch({ type: 'SIGNOUT_FAILURE', err})
        })
    }
}

export const signUp = (newUser) => {
    return (dispatch, getState, {getFirestore}) => {
        const firestore = getFirestore();

        firebase.auth().createUserWithEmailAndPassword(
            newUser.email,
            newUser.password
        ).then( (resp) => {
            return firestore.collection('users').doc(resp.user.uid).set({
                firstName: newUser.firstName,
                lastName: newUser.lastName,
                initials: newUser.firstName[0] + newUser.lastName[0]
            })
        } ).then( () => {
            dispatch({ type: 'SIGNUP_SUCCESS' })
        }).catch(err => {
            dispatch({ type: 'SIGNUP_ERROR', err })
        })
    }
}
