import React, { Component } from 'react'
import Map from './Map'
import { connect } from 'react-redux'
import { firestoreConnect, isLoaded } from 'react-redux-firebase'
import { compose } from 'redux'
import './Dashboard.css'
// import rainClose from './rainClose.png'

class Dashboard extends Component {

    render() {
        if (!isLoaded(this.props.sites)) return <div>Loading...</div>;
        // const { sites } = this.props;
        return (
            <div className="dashboard">
                <div className="row">
                    <div className="col s12">
                        <div className="z-depth-1 uses">
                            <h4 className="center uses-title">Ground Truth for:</h4>
                            <div className="row uses-desc">
                                <div className="col s3 m3">Historic<br />Landcover<br />Record</div>
                                <div className="col s3 m3">Images<br />Calibration<br />Sites</div>
                                <div className="col s3 m3">Accuracy<br />Assessment</div>
                                <div className="col s3 m3">Training<br />Neural<br />Networks</div>
                            </div>
                        </div>
                        {/* <div className="row">
                            <div className="col s12 offset-m3 m9">
                                <img className="rain-img" src={rainClose} alt="" />
                            </div>
                        </div> */}
                        <Map />
                        {this.state && this.state.imageUrl ? <img src={this.state.imageUrl} alt=""/> : null}
                        {/* <SiteList sites={sites} /> */}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return{
        sites: state.firestore.ordered.sites || state.site.sites
    };
}


export default compose(
    firestoreConnect([{ collection: 'sites', orderBy: 'title' }]),
    connect(mapStateToProps)
)(Dashboard);
