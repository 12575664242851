import React, { Component } from 'react'
import { connect } from 'react-redux'
import { createSite } from '../../store/actions/siteActions'
import { Redirect } from 'react-router-dom'

class CreateSite extends Component {
    state = {
        title: '',
        coordinates: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.createSite(this.state);
        e.target.reset();
        this.props.history.push('/');
    }
    render() {
        const { auth } = this.props;
        if (!auth.uid) return <Redirect to='/signin'/>;
        return (
            <div className="container">
                <form className="white z-depth-2" onSubmit={this.handleSubmit}>
                    <h5 className="grey-text text-darken-3">Create a New Site</h5>
                    <div className="input-field">
                        <input type="text" id='title' onChange={this.handleChange} />
                        <label htmlFor="title">Site Title</label>
                    </div>
                    <div className="input-field">
                        <textarea id="coordinates" className="materialize-textarea" onChange={this.handleChange}></textarea>
                        <label htmlFor="coordinates">Site Coordinates</label>
                    </div>
                    <div className="input-field">
                        <button className="btn indigo accent-1">Create</button>
                    </div>
                </form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = dispatch => {
    return {
        createSite: (site) => dispatch(createSite(site))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateSite)