
export const createSite = (site) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make some async call to database
        const firestore = getFirestore();
        const profile = getState().firebase.profile;
        const authorId = getState().firebase.auth.uid;
        firestore.collection('sites').add({
            ...site,
            authorFirstName: profile.firstName,
            authorLastName: profile.lastName,
            authorId: authorId,
            createdAt: new Date()
        }).then( () => {
            dispatch({ type: 'CREATE_SITE', site: site })
        } ).catch( (err) => {
            dispatch({ type: 'CREATE_SITE_ERROR', err })
        } );
    }
}

export const addSpectra = (siteId, spectra, isExtraData, isMetaLink, secondary) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make some async call to database
        let maxNumInDate, numberOfItems;
        let dataForDate = {};
        let { date, spectraExtraInfo, ...otherSpectraInfo } = spectra;
        const firestore = getFirestore();
        const sitesString = secondary ? 'secondarySites' : 'sites';
        const docRef = firestore.collection(`${sitesString}/${siteId}/${date}`).doc(spectra.instrument);
        // dataForDate[spectra.fileLink] = [ spectra.name, "N/A" ];
        
        docRef.get()
        .then(doc => {
            if(doc.exists) {
                let docData = doc.data();
                numberOfItems = docData.num;
                maxNumInDate = docData.num;
                for (let dateName in docData) {
                    console.log(dateName);
                    if(dateName === "num") {
                        continue
                    }
                    const tempNum = dateName.split('data')[1];
                    if (Number(tempNum) > maxNumInDate) {
                        maxNumInDate = Number(tempNum);
                    }
                }
                if(isExtraData) {
                    dataForDate[`data${maxNumInDate + 1}`] = [spectra.fileLink, spectra.name, spectraExtraInfo, isExtraData, false, '', '', '', '', ''];
                }
                else {
                    dataForDate[`data${maxNumInDate + 1}`] = [spectra.fileLink, spectra.name, spectra.metaLink, isExtraData, isMetaLink,  spectra.kmzLink, spectra.kmzName, spectra.kmzDescription, spectra.metaName, spectra.imageLink];
                }
                docRef.update({ ...dataForDate, num: numberOfItems+1}).then(() => {
                    if( !isExtraData ) {
                        firestore.collection(`${sitesString}/${siteId}/spectras`).add({
                            ...otherSpectraInfo,
                            date: new Date(date)
                        })
                        .then(() => {
                            dispatch({ type: 'ADD_SPECTRA', spectra: spectra })
                        }).catch((err) => {
                            dispatch({ type: 'ADD_SPECTRA_ERROR', err })
                        });
                    }
                    else {
                        dispatch({ type: 'ADD_SPECTRA', spectra: spectra })
                    }
                }).catch((err) => {
                    dispatch({ type: 'ADD_SPECTRA_ERROR', err })
                });
            }
            else {
                if (isExtraData) {
                    dataForDate["data1"] = [spectra.fileLink, spectra.name, spectraExtraInfo, isExtraData, false, '', '', '', '', ''];
                }
                else {
                    dataForDate["data1"] = [spectra.fileLink, spectra.name, spectra.metaLink, isExtraData, isMetaLink, spectra.kmzLink, spectra.kmzName, spectra.kmzDescription, spectra.metaName, spectra.imageLink];
                }
                firestore.collection(`${sitesString}/${siteId}/${date}`).doc(spectra.instrument).set({
                    ...dataForDate,
                    num: 1
                }).then(() => {
                    if (!isExtraData) {
                        firestore.collection(`${sitesString}/${siteId}/spectras`).add({
                            ...otherSpectraInfo,
                            date: new Date(date)
                        })
                        .then(() => {
                            dispatch({ type: 'ADD_SPECTRA', spectra: spectra })
                        }).catch((err) => {
                            dispatch({ type: 'ADD_SPECTRA_ERROR', err })
                        });
                    }
                    else {
                        dispatch({ type: 'ADD_SPECTRA', spectra: spectra })
                    }
                }).catch((err) => {
                    dispatch({ type: 'ADD_SPECTRA_ERROR', err })
                });
            }
        })
    }
}

export const addMulti = (siteId, multi, isExtraData, isMetaLink, secondary) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make some async call to database
        let maxNumInDate, numberOfItems;
        let dataForDate = {};
        let { date, multiExtraInfo, ...otherMultiInfo } = multi;
        const firestore = getFirestore();
        const sitesString = secondary ? 'secondarySites' : 'sites';
        const docRef = firestore.collection(`${sitesString}/${siteId}/${date}/`).doc(multi.sensor);
        // dataForDate[multi.fileLink] = [multi.name, "N/A"];
        docRef.get()
        .then(doc => {
            if (doc.exists) {
                let docData = doc.data();
                numberOfItems = docData.num;
                maxNumInDate = docData.num;
                for (let dateName in docData) {
                    console.log(dateName);
                    if (dateName === "num") {
                        continue
                    }
                    const tempNum = dateName.split('data')[1];
                    if (Number(tempNum) > maxNumInDate) {
                        maxNumInDate = Number(tempNum);
                    }
                }
                if (isExtraData) {
                    dataForDate[`data${maxNumInDate + 1}`] = [multi.fileLink, multi.name, multiExtraInfo, isExtraData, false, '', '', '', '', ''];
                }
                else {
                    dataForDate[`data${maxNumInDate + 1}`] = [multi.fileLink, multi.name, multi.metaLink, isExtraData, isMetaLink, multi.kmzLink, multi.kmzName, multi.kmzDescription, multi.metaName, multi.imageLink];
                }
                docRef.update({ ...dataForDate, num: numberOfItems+1}).then(() => {
                    if( !isExtraData ){
                        firestore.collection(`${sitesString}/${siteId}/multi`).add({
                            ...otherMultiInfo,
                            date: new Date(date)
                        })
                        .then(() => {
                            dispatch({ type: 'ADD_MULTI', multi: multi })
                        }).catch((err) => {
                            dispatch({ type: 'ADD_MULTI_ERROR', err })
                        });
                    }
                    else {
                        dispatch({ type: 'ADD_MULTI', multi: multi })
                    }
                }).catch((err) => {
                    dispatch({ type: 'ADD_MULTI_ERROR', err })
                });
            }
            else {
                if (isExtraData) {
                    dataForDate["data1"] = [multi.fileLink, multi.name, multiExtraInfo, isExtraData, false, '', '', '', '', ''];
                }
                else {
                    dataForDate["data1"] = [multi.fileLink, multi.name, multi.metaLink, isExtraData, isMetaLink, multi.kmzLink, multi.kmzName, multi.kmzDescription, multi.metaName, multi.imageLink];
                }
                firestore.collection(`${sitesString}/${siteId}/${date}`).doc(multi.sensor).set({
                    ...dataForDate,
                    num: 1
                }).then(() => {
                    if (!isExtraData) {
                        firestore.collection(`${sitesString}/${siteId}/multi`).add({
                            ...otherMultiInfo,
                            date: new Date(date)
                        })
                        .then(() => {
                            dispatch({ type: 'ADD_MULTI', multi: multi })
                        }).catch((err) => {
                            dispatch({ type: 'ADD_MULTI_ERROR', err })
                        });
                    }
                    else {
                        dispatch({ type: 'ADD_MULTI', multi: multi })
                    }
                }).catch((err) => {
                    dispatch({ type: 'ADD_MULTI_ERROR', err })
                });
            }
        })
    }
}

export const addHyper = (siteId, hyper, isExtraData, isMetaLink, secondary) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make some async call to database
        let maxNumInDate, numberOfItems;
        let dataForDate = {};
        let { date, hyperExtraInfo, ...otherHyperInfo } = hyper;
        const firestore = getFirestore();
        const sitesString = secondary ? 'secondarySites' : 'sites';
        const docRef = firestore.collection(`${sitesString}/${siteId}/${date}/`).doc(hyper.sensor);
        // dataForDate[hyper.fileLink] = [hyper.name, "N/A"];
        docRef.get()
        .then(doc => {
            if (doc.exists) {
                let docData = doc.data();
                numberOfItems = docData.num;
                maxNumInDate = docData.num;
                for (let dateName in docData) {
                    console.log(dateName);
                    if (dateName === "num") {
                        continue
                    }
                    const tempNum = dateName.split('data')[1];
                    if (Number(tempNum) > maxNumInDate) {
                        maxNumInDate = Number(tempNum);
                    }
                }
                if (isExtraData) {
                    dataForDate[`data${maxNumInDate + 1}`] = [hyper.fileLink, hyper.name, hyperExtraInfo, isExtraData, false, '', '', '', '', ''];
                }
                else {
                    dataForDate[`data${maxNumInDate + 1}`] = [hyper.fileLink, hyper.name, hyper.metaLink, isExtraData, isMetaLink, hyper.kmzLink, hyper.kmzName, hyper.kmzDescription, hyper.metaName, hyper.imageLink];
                }
                docRef.update({ ...dataForDate, num: numberOfItems+1}).then(() => {
                    if( !isExtraData ) {
                        firestore.collection(`${sitesString}/${siteId}/hyper`).add({
                            ...otherHyperInfo,
                            date: new Date(date)
                        })
                        .then(() => {
                            dispatch({ type: 'ADD_HYPER', hyper: hyper })
                        }).catch((err) => {
                            dispatch({ type: 'ADD_HYPER_ERROR', err })
                        });
                    }
                    else {
                        dispatch({ type: 'ADD_HYPER', hyper: hyper })
                    }
                }).catch((err) => {
                    dispatch({ type: 'ADD_HYPER_ERROR', err })
                });
            }
            else {
                if (isExtraData) {
                    dataForDate["data1"] = [hyper.fileLink, hyper.name, hyperExtraInfo, isExtraData, false, '', '', '', '', ''];
                }
                else {
                    dataForDate["data1"] = [hyper.fileLink, hyper.name, hyper.metaLink, isExtraData, isMetaLink, hyper.kmzLink, hyper.kmzName, hyper.kmzDescription, hyper.metaName, hyper.imageLink];
                }
                firestore.collection(`${sitesString}/${siteId}/${date}`).doc(hyper.sensor).set({
                    ...dataForDate,
                    num: 1
                }).then(() => {
                    if (!isExtraData) {
                        firestore.collection(`${sitesString}/${siteId}/hyper`).add({
                            ...otherHyperInfo,
                            date: new Date(date)
                        })
                        .then(() => {
                            dispatch({ type: 'ADD_HYPER', hyper: hyper })
                        }).catch((err) => {
                            dispatch({ type: 'ADD_HYPER_ERROR', err })
                        });
                    }
                    else {
                        dispatch({ type: 'ADD_HYPER', hyper: hyper })
                    }
                }).catch((err) => {
                    dispatch({ type: 'ADD_HYPER_ERROR', err })
                });
            }
        })
    }
}

export const addRGB = (siteId, rgb, secondary) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make some async call to database
        let numCurrentDates;
        let rgbToAdd = {};

        let collectionRef;
        const firestore = getFirestore();
        if (!secondary) {
            collectionRef = firestore.collection(`sites/${siteId}/rgb`);
        }
        else {
            collectionRef = firestore.collection(`secondarySites/${siteId}/rgb`);
        }
        const docRef = collectionRef.doc(rgb.polygonName);
        docRef.get()
            .then( doc => {
                if(doc.exists) {
                    numCurrentDates = doc.data().num;
                    rgbToAdd[`date${numCurrentDates + 1}`] = {
                        date: new Date(rgb.date),
                        rgbLink: rgb.rgbLink,
                        class: rgb.class
                    };
                    rgbToAdd.num = numCurrentDates + 1;
                    docRef.set(rgbToAdd, { merge: true })
                        .then( () => {
                            dispatch({ type: 'ADD_RGB', rgb: rgb });
                        } )
                        .catch( insideError => {
                            dispatch({ type: 'ADD_RGB_ERROR', err: insideError })
                        } );
                }
                else {
                    // doc.data() will be undefined in this case
                    docRef.set({
                        date1: {
                            date: new Date(rgb.date),
                            rgbLink: rgb.rgbLink,
                            class: rgb.class
                        },
                        polygonLink: rgb.polygonLink,
                        num: 1
                    })
                        .then(() => {
                            dispatch({ type: 'ADD_RGB', rgb: rgb });
                        })
                        .catch(insideError => {
                            dispatch({ type: 'ADD_RGB_ERROR', err: insideError })
                        });
                }
            } )
            .catch( error => {
                dispatch({ type: 'ADD_RGB_ERROR', err: error })
            });
    }
}

export const deleteRow = (siteId, id, data, dataType, secondary) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make some async call to database
        const firestore = getFirestore();
        const sitesString = secondary ? 'secondarySites' : 'sites';
        const { dateName, ...realData } = data;
        let requestedRgb = {};
        requestedRgb[dateName] = firestore.FieldValue.delete();
        if(dataType === 'rgb'){
            const fileRef = firestore.collection(`${sitesString}/${siteId}/rgb`).doc(id);
            fileRef.update({ ...requestedRgb, num: firestore.FieldValue.increment(-1) })
                .then( () => {
                    fileRef.get()
                        .then( doc => {
                            if(doc.data().num === 0) {
                                fileRef.delete()
                                    .then( () => {
                                        dispatch({ type: 'DELETE_DATA', realData, dataType })
                                    })
                                    .catch(err => {
                                        dispatch({ type: 'DELETE_DATA_ERROR', err })
                                    })
                            }
                            else {
                                dispatch({ type: 'DELETE_DATA', realData, dataType })
                            }
                        })
                        .catch( err => {
                            dispatch({ type: 'DELETE_DATA_ERROR', err })
                        })
                })
                .catch( err => {
                    dispatch({ type: 'DELETE_DATA_ERROR', err })
                })
        }
        else {
            firestore.collection(`${sitesString}/${siteId}/${dataType}`).doc(id).delete()
                .then(() => {
                    const date = realData.date.toDate();
                    let instrumentDoc;
                    let year, month, day;
                    year = date.getFullYear();
                    month = date.getMonth() + 1;
                    day = date.getDate();
                    month = month < 10 ? `0${month}` : month;
                    day = day < 10 ? `0${day}` : day;

                    if(dataType === 'spectra') {
                        instrumentDoc = firestore.collection(`${sitesString}/${siteId}/${year}-${month}-${day}`).doc(realData.instrument);
                    }
                    else {
                        instrumentDoc = firestore.collection(`${sitesString}/${siteId}/${year}-${month}-${day}`).doc(realData.sensor);
                    }
                    instrumentDoc.get()
                    // instrumentDoc.update({
                    //     [`${data.fileLink}`]: firestore.FieldValue.delete() 
                    // })
                    .then((doc) => {
                        console.log(doc.data());
                        const docData = doc.data();
                        let updatedDoc = {};
                        let dateStringToDelete = '';
                        for (let savedDate in docData) {
                            if (docData[savedDate][0] === data.fileLink) {
                                dateStringToDelete = savedDate;
                                updatedDoc[dateStringToDelete] = firestore.FieldValue.delete();
                                updatedDoc["num"] = firestore.FieldValue.increment(-1);
                                break;
                            }
                        }
                        instrumentDoc.update(updatedDoc)
                        .then( () => {
                            instrumentDoc.get()
                                .then(doc => {
                                    if (doc.data().num === 0) {
                                        instrumentDoc.delete()
                                            .then(() => {
                                                dispatch({ type: 'DELETE_DATA', realData, dataType })
                                            })
                                            .catch(err => {
                                                dispatch({ type: 'DELETE_DATA_ERROR', err })
                                            })
                                    }
                                    else {
                                        dispatch({ type: 'DELETE_DATA', realData, dataType })
                                    }
                                })
                                .catch(err => {
                                    dispatch({ type: 'DELETE_DATA_ERROR', err })
                                })
                        })
                    })
                    .catch((err) => {
                        dispatch({ type: 'DELETE_DATA_ERROR', err })
                    });
                })
                .then(() => {
                    dispatch({ type: 'DELETE_DATA', data, dataType })
                })
                .catch((err) => {
                    dispatch({ type: 'DELETE_DATA_ERROR', err })
                });
        }
    }
}

export const deleteSpecificDateData = (siteId, stringDate, realData, secondary) => {
    return (dispatch, getState, { getFirebase, getFirestore }) => {
        // make some async call to database
        const firestore = getFirestore();
        const sitesString = secondary ? 'secondarySites' : 'sites';
        // const date = new Date(stringDate);
        // let splittedDate = stringDate.split('-');
        // let year, month, day;
        // year = splittedDate[0];
        // month = splittedDate[1];
        // day = splittedDate[2];
        // month = month < 10 ? `0${month}` : month;
        // day = day < 10 ? `0${day}` : day;

        const instrumentDoc = firestore.collection(`${sitesString}/${siteId}/${stringDate}`).doc(realData.instrument);
        instrumentDoc.get()
            .then((doc) => {
                console.log(doc.data());
                const docData = doc.data();
                let updatedDoc = {};
                let dateStringToDelete = '';
                for (let savedDate in docData) {
                    if (docData[savedDate][0] === realData.fileLink) {
                        dateStringToDelete = savedDate;
                        updatedDoc[dateStringToDelete] = firestore.FieldValue.delete();
                        updatedDoc["num"] = firestore.FieldValue.increment(-1);
                        break;
                    }
                }
                instrumentDoc.update(updatedDoc)
                    .then(() => {
                        instrumentDoc.get()
                            .then(doc => {
                                if (doc.data().num === 0) {
                                    instrumentDoc.delete()
                                        .then(() => {
                                            dispatch({ type: 'DELETE_DATA', realData })
                                        })
                                        .catch(err => {
                                            dispatch({ type: 'DELETE_DATA_ERROR', err })
                                        })
                                }
                                else {
                                    dispatch({ type: 'DELETE_DATA', realData })
                                }
                            })
                            .catch(err => {
                                dispatch({ type: 'DELETE_DATA_ERROR', err })
                            })
                    })
            })
            .catch((err) => {
                dispatch({ type: 'DELETE_DATA_ERROR', err })
            });
    }
}


export const chooseSite = (siteName) => {
    return (dispatch) => {
        dispatch({ type: 'CHOSE_SITE', chosenSite: siteName })
    }
}