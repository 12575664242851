import React, { Component } from 'react';
import { Link, withRouter, NavLink } from 'react-router-dom';
import './Navbar.css';
import UserDropdown from '../dropdown/UserDropdown'
import { connect } from "react-redux";
import { compose } from 'redux';
import { checkIfAdmin } from '../../store/actions/adminActions'

class Navbar extends Component {
    state = {
        dropdownEnabled: false
    }
    componentDidMount() {
        if(this.props.auth.uid) {
            this.props.checkIfAdmin(this.props.auth.uid);
        }
    }
    componentDidUpdate(prevProps) {
        const currentUID = this.props.auth.uid;
        if (currentUID !== prevProps.auth.uid) {
            this.props.checkIfAdmin(this.props.auth.uid);
        }
    }
    render(){
        const { profile } = this.props;
        return (
            <nav className="nav-wrapper">
                <Link to={this.props.location.pathname} className="brand-logo hide-on-med-and-down">
                    <span className="white-text">Open Access</span> Long-Term Ground Truth Network<br/>Along a Mediterranean to Arid Climatic Gradient
                </Link>
                <Link to={this.props.location.pathname} className="brand-logo hide-on-large-only">
                    <span className="white-text">Open Access</span> Long-Term Ground Truth Network
                </Link>
                <ul className="right hide-on-med-and-down">
                    <li><NavLink to={this.props.location.pathname} className="dropdown-trigger" data-target="user-options">User Options</NavLink></li>
                </ul>
                <UserDropdown profile={profile}/>
            </nav>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        profile: state.firebase.profile,
        auth: state.firebase.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        checkIfAdmin: (uid) => dispatch(checkIfAdmin(uid))
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(Navbar);