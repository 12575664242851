import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';

var firebaseConfig = {
    apiKey: "AIzaSyDVEJlcg9omwTf7m1D5aE9xm5CkWteuw9c",
    authDomain: "long-term-ground-truth-network.firebaseapp.com",
    databaseURL: "https://long-term-ground-truth-network.firebaseio.com",
    projectId: "long-term-ground-truth-network",
    storageBucket: "long-term-ground-truth-network.appspot.com",
    messagingSenderId: "132574870992",
    appId: "1:132574870992:web:7ace68b6eb46f24311cdd5",
    measurementId: "G-XM0WT7SC90"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore();

export default firebase;