import React, { Component } from 'react'

// import { Document, Page } from 'react-pdf'
// import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import PDFViewer from 'pdf-viewer-reactjs'


// import pdfSample from '/gradientsample.pdf'
import './PdfFile.styles.scss'
import './bulma-css/bulma.scss'

// PDFViewer.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${PDFViewer.pdfjs.version}/pdf.worker.js`;

export default class PdfFile extends Component {
    
    render() {
        const { pdfSource } = this.props;

        return (
            <div className="pdf-file">
                <div className="row">
                    {/* <div className="col s12 m2">
                        <button onClick={() => { if (pageNumber > 1) this.goToPrevPage() }}>Prev</button>
                        <button onClick={() => { if (pageNumber < numPages) this.goToNextPage() }}>Next</button>
                    </div> */}
                    <div className="col s12 m8 offset-m2">
                        <div className="pdf-doc">
                            <PDFViewer
                                canvasCss="pdf-canvas"
                                document={{ url: pdfSource }}
                                navbarOnTop={true}
                                scale={0.7}
                                scaleStep={0.1}
                                maxScale={2.0}
                                minScale={0.7}
                                hideRotation={true} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}