import React, { Component } from 'react'
import { NavLink, Link, withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { compose } from 'redux';
import DropdownMenu from './Dropdown';
import { firestoreConnect } from 'react-redux-firebase';
import { chooseSite } from '../../store/actions/siteActions'
import './SideNavbar.css';

class SideNavbar extends Component {
    state = {
        dropdownEnabled: false
    }
    componentDidUpdate(prevProps) {
        const M = window.M;
        if ((prevProps.sites !== this.props.sites && this.props.secondarySites && this.props.secondarySites.length > 0 && this.state.dropdownEnabled === false)
            || (prevProps.secondarySites !== this.props.secondarySites && this.props.sites && this.props.sites.length > 0 && this.state.dropdownEnabled === false) ){
            var dropdownElem = document.querySelectorAll('.dropdown-trigger');
            var optionsD = {
                coverTrigger: false
            }
            M.Dropdown.init(dropdownElem, optionsD);
            this.setState({
                dropdownEnabled: true
            })
        }
    }
    componentDidMount() {
        const M = window.M;
        var elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {
            edge: "left",
            draggable: true
        })
    }
    render() {
        const { sites, secondarySites, chooseSite } = this.props;
        // console.log(this.props);
        return (
            <div>
                <ul id="slide-out" className="sidenav sidenav-fixed">
                    <li>
                        <div className="logo-container">
                            {/* <img src={spectraBlue} alt="" /> */}
                            <i className="material-icons white-text">multiline_chart</i>
                            <Link to="/"><span className="white-text">Remote Sensing LTGTN</span></Link>
                        </div>
                    </li>
                    <li className="no-padding">
                        <ul>
                            {/* ------------------ Main Sites ------------------ */}
                            <li><NavLink className="section-header" to={this.props.location.pathname}><span>Main Sites</span></NavLink></li>
                            {sites && sites.map(site => {
                                return (
                                    <li key={site.id}>
                                        <NavLink onClick={() => chooseSite(site.title)} className="dropdown-trigger" data-target={site.id} to={`/site/${site.id}`}>{site.title}</NavLink>
                                    </li>
                                )
                            })}
                            {sites && sites.map(site => {
                                return (
                                    <DropdownMenu key={site.id} dropdownId={site.id} />
                                )
                            })}
                            {/* ------------------ Secondary Sites ------------------ */}
                            <li><NavLink className="section-header" to={this.props.location.pathname}><span>Secondary Sites</span></NavLink></li>
                            {secondarySites && secondarySites.map(site => {
                                return (
                                    <li key={site.id}>
                                        <NavLink onClick={() => chooseSite(site.title)} className="dropdown-trigger" data-target={site.id} to={`/site/${site.id}`}>{site.title}</NavLink>
                                    </li>
                                )
                            })}
                            {secondarySites && secondarySites.map(site => {
                                return (
                                    <DropdownMenu key={site.id} dropdownId={site.id} />
                                )
                            })}
                            {/* <li><NavLink className="secondary-sites-item" to={this.props.location.pathname}>Zecharia</NavLink></li>
                            <li><NavLink className="secondary-sites-item" to={this.props.location.pathname}>Tel Lachis</NavLink></li> */}
                            {/* ------------------ Data Sources ------------------ */}
                            <li><NavLink className="section-header" to={this.props.location.pathname}><span>Data Sources</span></NavLink></li>
                            <li><NavLink className="data-sources-item" to='/gradient'>Gradient</NavLink></li>
                            <li><NavLink className="data-sources-item" to='/sensors'>Sensors</NavLink></li>
                            <li><NavLink className="data-sources-item" to='/site-descriptions'>Site Descriptions</NavLink></li>
                            <li><NavLink className="data-sources-item" to='/disclaimer'>DISCLAIMER</NavLink></li>
                        </ul>
                    </li>
                </ul>
                <Link to={this.props.location.pathname} data-target="slide-out" className="sidenav-trigger"><i className="material-icons">menu</i></Link>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    // console.log(state);
    return {
        sites: state.firestore.ordered.sites || state.site.sites,
        secondarySites: state.firestore.ordered.secondarySites || state.site.secondarySites
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        chooseSite: (siteName) => dispatch(chooseSite(siteName))
    }
}


export default compose(
    firestoreConnect( props => [
        { collection: 'sites', orderBy: 'title', storeAs: 'sites' },
        { collection: 'secondarySites', orderBy: 'title', storeAs: 'secondarySites' }
    ]),
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(SideNavbar);
