import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { addSpectra } from '../../store/actions/siteActions'
import PopupImage from './PopupImage/PopupImage.component'
import DeleteButton from './DeleteButton/DeleteButton.component'
import Map from '../dashboard/Map'
import * as URI from 'uri-js'
import './tables.scss'

class SpectraTable extends Component {
    state = {
        date: '',
        instrument: '',
        name: '',
        fileLink: '',
        imageLink: '',
        rgbLink: '',
        metaName: '',
        metaLink: '',
        isMetaLink: false,
        kmzName: '',
        kmzLink: '',
        kmzDescription: '',
        spectraExtraInfo: ''
    }
    checkUrls = (urlArray) => {
        return urlArray.map(url => {
            if (URI.parse(url).scheme == null) {
                return 'https://' + url;
            }
            return url;
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let {isMetaLink, ...spectra} = this.state;
        let { secondary } = this.props;

        let checkboxChecked = document.getElementById("extra-data").checked;

        const urlsToCheck = isMetaLink ? [spectra.fileLink, spectra.imageLink, spectra.rgbLink, spectra.kmzLink, spectra.metaLink] :
                                        [spectra.fileLink, spectra.imageLink, spectra.rgbLink, spectra.kmzLink];
        const fixedURLS = this.checkUrls(urlsToCheck);
        spectra.fileLink = fixedURLS[0];
        spectra.imageLink = fixedURLS[1];
        spectra.rgbLink = fixedURLS[2];
        spectra.kmzLink = fixedURLS[3];
        if(isMetaLink) {
            spectra.metaLink = fixedURLS[4];
        }

        spectra.date = spectra.date.slice(0, 4) + '-' + spectra.date.slice(4, 6) + '-' + spectra.date.slice(6);
        this.props.addSpectra(this.props.siteId, spectra, checkboxChecked, isMetaLink, secondary);
        let spectraOnlyElems = document.getElementsByClassName("spectra-only");
        let extraInfoElem = document.getElementById("spectra-extra-info");
        for (let i = 0; i < spectraOnlyElems.length; i++) {
            spectraOnlyElems[i].style.display = "block";
        }
        extraInfoElem.style.display = "none";

        e.target.reset();
    }
    toggleExtraData = () => {
        let checkboxChecked = document.getElementById("extra-data").checked;
        let spectraOnlyElems = document.getElementsByClassName("spectra-only");
        let extraInfoElem = document.getElementById("spectra-extra-info");
        for (let i=0; i<spectraOnlyElems.length; i++) {
            if(checkboxChecked) {
                spectraOnlyElems[i].style.display = "none";
                extraInfoElem.style.display = "block";
            }
            else {
                spectraOnlyElems[i].style.display = "block";
                extraInfoElem.style.display = "none";
            }
        }
    }
    toggleMetaLink = () => {
        this.setState({
            ...this.state,
            isMetaLink: !this.state.isMetaLink
        })
    }

    isURL = (str) => {
        const pattern = new RegExp('^(https:\\/\\/)'); // fragment locator
        return !!pattern.test(str);
    }

    render() {
        const { site, siteId, auth, spectras, isAdmin, secondary } = this.props;
        var spectraElems = [];
        let dateString, removedDayString;
        // if (!auth.uid) return <Redirect to='/signin' />;
        if (site) {
            for (var specKey in spectras) {
                if (spectras.hasOwnProperty(specKey) && spectras[specKey]) {
                    dateString = spectras[specKey].date.toDate().toDateString();
                    removedDayString = dateString.slice(dateString.indexOf(" ") + 1);
                    const metaData = spectras[specKey].metaLink;
                    if(auth.uid) {
                        spectraElems.push((
                            <tr key={specKey}>
                                <td>{removedDayString}</td>
                                <td>{spectras[specKey].instrument}</td>
                                <td>{spectras[specKey].fileLink !== "https://" ? <a href={spectras[specKey].fileLink} target="_blank" rel="noopener noreferrer">{spectras[specKey].name}</a> : '- - -'}</td>
                                <td>{spectras[specKey].rgbLink !== "https://" ? <a href={spectras[specKey].rgbLink} target="_blank" rel="noopener noreferrer">RGB Link</a> : '- - -'}</td>
                                <td>{this.isURL(metaData) ? <a href={spectras[specKey].metaLink} target="_blank" rel="noopener noreferrer">Meta Link</a> : '- - -'}</td>
                                <td>{spectras[specKey].kmzLink !== "https://" ? <a href={spectras[specKey].kmzLink} target="_blank" rel="noopener noreferrer">KMZ Link</a> : '- - -'}</td>
                                {isAdmin ? <DeleteButton siteId={siteId} id={specKey} data={spectras[specKey]} dataType="spectras" secondary={secondary} /> : null}
                            </tr>
                        ));
                    }
                    else {
                        spectraElems.push((
                            <tr key={specKey}>
                                <td>{removedDayString}</td>
                                <td>{spectras[specKey].instrument}</td>
                                <td><PopupImage imageUrl={spectras[specKey].imageLink} name={spectras[specKey].name} tableClass="spectra-table" /></td>
                                <td><PopupImage imageUrl={spectras[specKey].rgbLink} name="RGB Quick Look" tableClass="spectra-table" /></td>
                                <td>{this.isURL(metaData) ? <a href={spectras[specKey].metaLink} target="_blank" rel="noopener noreferrer">Meta Link</a> : '- - -'}</td>
                                <td>{spectras[specKey].kmzLink !== "https://" ? <a href={spectras[specKey].kmzLink} target="_blank" rel="noopener noreferrer">KMZ Link</a> : '- - -'}</td>
                            </tr>
                        ));
                    }
                }
            }
            return (
                <div className="spectra-table row">
                    <div className="col s12 m9 spectra-table-inside">
                        <div className="white data-table">
                            <h4>{site ? site.title : null} / Spectra</h4>
                            <table className="striped highlight responsive-table">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Instrument</th>
                                        <th>File Link</th>
                                        <th>RGBs Link</th>
                                        <th>Meta Link</th>
                                        <th>KMZ Link</th>
                                        {(auth.uid && isAdmin) ? <th>Delete Spectra</th> : null}
                                    </tr>
                                </thead>

                                <tbody>
                                    {spectraElems && spectraElems.map(spec => {
                                        return spec;
                                    })}
                                </tbody>
                            </table>
                            {(auth.uid && isAdmin) ? (
                                <form className="white z-depth-2" onSubmit={this.handleSubmit}>
                                    <h5 className="grey-text text-darken-3">Add a new Spectra:</h5>
                                    <div className="input-field">
                                        <input type="text" id='date' onChange={this.handleChange} />
                                        <label htmlFor="date">Date</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id='instrument' onChange={this.handleChange} />
                                        <label htmlFor="instrument">Instrument</label>
                                    </div>
                                    <div className="input-field">
                                        <textarea id="name" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="name">File Name</label>
                                    </div>
                                    <div className="input-field">
                                        <textarea id="fileLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="fileLink">File Link</label>
                                    </div>
                                    <div className="input-field spectra-only">
                                        <textarea id="imageLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="imageLink">Quick-Look Image Link (Direct link to image)</label>
                                    </div>
                                    <div className="input-field spectra-only">
                                        <textarea id="rgbLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="rgbLink">RGB Image Link</label>
                                    </div>
                                    <div className="row">
                                        <div className="input-field spectra-only col s6">
                                            <textarea id="metaName" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="metaName">Meta {this.state.isMetaLink ? "Link Name" : "Information"}</label>
                                        </div>
                                        {this.state.isMetaLink ? (
                                            <div className="input-field spectra-only col s6">
                                                <textarea id="metaLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                                <label htmlFor="metaLink">Meta Link</label>
                                            </div>
                                        ) : null}
                                        <div className="spectra-only col s6">
                                            <label htmlFor="isMetaLink">
                                                <div className="row"></div>
                                                <div className="row"></div>
                                                <div className="row">
                                                    <input type="checkbox" id="isMetaLink" onClick={this.toggleMetaLink} />
                                                    <span className="isMetaLink-text"><b>Is Meta Information a link?</b></span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field spectra-only col s4">
                                            <textarea id="kmzName" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzName">KMZ Link Name</label>
                                        </div>
                                        <div className="input-field spectra-only col s4">
                                            <textarea id="kmzLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzLink">KMZ Link</label>
                                        </div>
                                        <div className="input-field spectra-only col s4">
                                            <textarea id="kmzDescription" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzDescription">KMZ Description</label>
                                        </div>
                                    </div>
                                    <div className="input-field" id="spectra-extra-info" style={{ display: "none" }}>
                                        <textarea id="spectraExtraInfo" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="spectraExtraInfo">Info Text</label>
                                    </div>
                                    <label htmlFor="extra-data">
                                        <input type="checkbox" id="extra-data" onClick={this.toggleExtraData}/>
                                        <span>Additional data for specific date (Not a spectra)</span>
                                    </label>
                                    <div className="input-field">
                                        <button className="btn indigo accent-1">Add</button>
                                    </div>
                                </form>
                            ) : null}
                        </div>
                    </div>
                    <div className="col s12 m3 side-map">
                        <Map />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="container center">
                    <p>Loading site...</p>
                </div>
            )
        }
    }
}


const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const sites = state.firestore.data.sites;
    const secondarySites = state.firestore.data.secondarySites;
    let site = null;
    let secondary = false;
    const spectras = state.firestore.data[id + '-spectras'];
    const isAdmin = state.auth.isAdmin;
    if (sites && sites[id]) {
        site = sites[id];
    }
    if (secondarySites && secondarySites[id]) {
        site = secondarySites[id];
        secondary = true;
    }
    return {
        siteId: id,
        site,
        spectras,
        auth: state.firebase.auth,
        isAdmin,
        secondary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addSpectra: (siteId, spectra, isExtraData, isMetaLink, secondary) => dispatch(addSpectra(siteId, spectra, isExtraData, isMetaLink, secondary))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect( (props) => {
        const secondary = props.secondary ? 'econdaryS' : '';
        return [
            {
                collection: `s${secondary}ites`,
                doc: props.match.params.id,
                subcollections: [{ collection: 'spectras' }],
                storeAs: props.match.params.id + '-spectras',
                orderBy: [['date', 'desc'], ['instrument', 'asc'], ['name', 'asc']]
            }
        ]   
    })
)(SpectraTable)