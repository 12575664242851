import React from 'react'
import PdfFile from '../PdfFile/PdfFile.component'

function SiteDescriptions() {
    return (
        <div>
            <PdfFile pdfSource="/sitedescsample.pdf" />
        </div>
    )
}

export default SiteDescriptions
