import React from 'react'
import { NavLink } from 'react-router-dom'
import { connect } from "react-redux"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

function DropdownMenu(props) {
    const { auth, dropdownId } = props;
    // <FontAwesomeIcon icon="bolt" />
    const signedInMenu = (
        <ul id={dropdownId} className="dropdown-content">
            <li><NavLink to={"/site/" + dropdownId}><FontAwesomeIcon icon="folder-open" color="black"/> Data Inventory</NavLink></li>
            <li><NavLink to={"/site/" + dropdownId + "/spectra"}><div>Spectra</div></NavLink></li>
            <li><NavLink to={"/site/" + dropdownId + "/multi"}><div>Airborne & Satellite Images</div></NavLink></li>
            <li><NavLink to={"/site/" + dropdownId + "/hyper"}><div>Drone & Ultralight Images</div></NavLink></li>
            <li><NavLink to={"/site/" + dropdownId + "/rgb"}><div>RGB - Close Range</div></NavLink></li>
        </ul>);
    const signedOutMenu = (<ul id={dropdownId} className="dropdown-content">
        <li><NavLink to={"/site/" + dropdownId}><FontAwesomeIcon icon="folder-open" color="black" /> Data Inventory</NavLink></li>
        <li><NavLink to={"/site/" + dropdownId + "/spectra"}>Spectra</NavLink></li>
        <li><NavLink to={"/site/" + dropdownId + "/multi"}><div>Airborne & Satellite Images</div></NavLink></li>
        <li><NavLink to={"/site/" + dropdownId + "/hyper"}><div>Drone Images</div></NavLink></li>
        <li><NavLink to={"/site/" + dropdownId + "/rgb"}><div>RGB - Close Range</div></NavLink></li>
    </ul>);
    const links = auth.uid ? signedInMenu : signedOutMenu;
    return (
        <div key={dropdownId}>
            {links}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    };
}


export default connect(mapStateToProps)(DropdownMenu);