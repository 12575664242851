import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { addRGB } from '../../store/actions/siteActions'
import DeleteButton from './DeleteButton/DeleteButton.component'
import * as URI from 'uri-js'
// import PopupImage from './PopupImage/PopupImage.component'
import RgbModal from './RgbModal/RgbModal.component'
import './tables.scss'

class RgbTable extends Component {
    state = {
        polygonName: '',
        polygonLink: '',
        date: '',
        rgbLink: '',
        class: '',
        modalEnabled: false
    }
    checkUrl = (url) => {
        if (URI.parse(url).scheme == null) {
            return 'https://' + url;
        }
        return url;
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let rgb = this.state;
        let { secondary } = this.props;

        rgb.rgbLink = this.checkUrl(rgb.rgbLink);
        rgb.polygonLink = this.checkUrl(rgb.polygonLink);

        rgb.date = rgb.date.slice(0, 4) + '-' + rgb.date.slice(4, 6) + '-' + rgb.date.slice(6);
        this.props.addRGB(this.props.siteId, rgb, secondary);
        e.target.reset();
    }
    componentDidUpdate(prevProps) {
        const M = window.M;
        if (prevProps.rgb !== this.props.rgb && this.state.modalEnabled === false) {
            var modalElem = document.querySelectorAll('.modal');
            M.Modal.init(modalElem, {});
            this.setState({
                modalEnabled: true
            })
        }
    }
    render(){ 
        const { auth, rgb, site, siteId, isAdmin, secondary } = this.props;
        var rgbElems = [];
        let headerElems = [];
        let maxNum = 0;
        let dateString, removedDateString;
        // rgbKey is the polygon
        if(site) {
            for (var rgbKey in rgb) {
                if (rgb.hasOwnProperty(rgbKey) && rgb[rgbKey]) {
                    const num = rgb[rgbKey]["num"];
                    maxNum = num > maxNum ? num : maxNum;
                }
            }
            for (rgbKey in rgb) {
                if (rgb.hasOwnProperty(rgbKey) && rgb[rgbKey]) {
                    let dateElems = [], emptyElems = [];
                    let sortedDates = [];
                    const num = rgb[rgbKey]["num"];
                    // maxNum = num > maxNum ? num : maxNum;
                    for(let date in rgb[rgbKey]) {
                        if (date !== 'num' && date !== 'polygonLink') {
                            sortedDates.push([date, rgb[rgbKey][date].date.toDate()]);
                        }
                        else {
                            continue;
                        }
                    }
                    // Sort The polygon dates
                    // Descending sort (from newest to oldest)
                    sortedDates.sort(function (a, b) { return b[1].getTime() - a[1].getTime() });
                    for (let i=0; i<sortedDates.length; i++) {
                        const dataSentToDelete = { ...rgb[rgbKey][sortedDates[i][0]], dateName: sortedDates[i][0] }
                        dateString = sortedDates[i][1].toDateString();
                        removedDateString = dateString.slice(dateString.indexOf(" ") + 1);
                        const randomModelKey = Math.floor(Math.random()*10000);
                        dateElems.push(
                            (<td className="td-date" key={`${sortedDates[i][0]}`}>{removedDateString}</td>),
                            auth.uid ? (<td key={`${sortedDates[i][0]}-link`}><a href={rgb[rgbKey][sortedDates[i][0]].rgbLink} target="_blank" rel="noopener noreferrer">Link</a></td>)
                                // : (<td key={`${sortedDates[i][0]}-link`}><PopupImage imageUrl={rgb[rgbKey][sortedDates[i][0]].rgbLink} name="RGB" tableClass="rgb-table" /></td>),
                                : (<td key={`${sortedDates[i][0]}-link`}><RgbModal rgbKey={`${randomModelKey}-${rgbKey}-${sortedDates[i][0]}-${dateString}-m`} imageUrl={rgb[rgbKey][sortedDates[i][0]].rgbLink} /></td>),
                            (<td key={`${sortedDates[i][0]}-class`}>{rgb[rgbKey][sortedDates[i][0]].class}</td>),
                        );
                        if (auth.uid) {
                            dateElems.push(
                                (<DeleteButton key={`${sortedDates[i][0]}-delete`} siteId={siteId} id={rgbKey} data={dataSentToDelete} dataType='rgb' secondary={secondary} />)
                            );
                        }
                    }
                    for (let i = num*3 + 1; i <= maxNum * 3; i++) {
                        emptyElems.push((<td key={`empty-${i}`}></td>));
                    }
                    rgbElems.push((
                        <tr key={rgbKey}>
                            <td><a href={rgb[rgbKey]["polygonLink"]} target="_blank" rel="noopener noreferrer">{rgbKey}</a></td>
                            {dateElems && dateElems.map(elem => elem)}
                            {emptyElems && emptyElems.map(elem => elem)}
                        </tr>
                    ));
                }
            }
            for (var i = 1; i <= maxNum; i++) {
                headerElems.push(
                    (<th key={`date${i}-hDate`}>Date {i}</th>),
                    (<th key={`date${i}-hRGB`}>RGB</th>),
                    (<th key={`date${i}-hClass`}>Class</th>)
                );
                if(auth.uid) {
                    headerElems.push(
                        (<th key={`delete-h-${i}`}>Delete Image</th>)
                    );
                }
            }
            return (
                <div className="container white data-table rgb-table">
                    <h4>{site.title} / RGB - Close Range</h4>
                    <div style={{"overflowX": "auto"}}>
                        <table className="striped highlight rgb-table-inside">
                            <thead>
                                <tr>
                                    {headerElems ? <th>polygon</th> : null}
                                    {headerElems && headerElems.map(h => h)}
                                </tr>
                            </thead>

                            <tbody>
                                {rgbElems && rgbElems.map(rgb => {
                                    return rgb;
                                })}
                            </tbody>
                        </table>
                    </div>
                    {auth.uid && isAdmin ? (
                        <form className="white z-depth-2" onSubmit={this.handleSubmit}>
                            <h5 className="grey-text text-darken-3">Add a new RGB Image:</h5>
                            <div className="input-field">
                                <input type="text" id='date' onChange={this.handleChange} />
                                <label htmlFor="date">Date</label>
                            </div>
                            <div className="input-field">
                                <input type="text" id='polygonName' onChange={this.handleChange} />
                                <label htmlFor="polygonName">Polygon Name</label>
                            </div>
                            <div className="input-field">
                                <input type="text" id='polygonLink' onChange={this.handleChange} />
                                <label htmlFor="polygonLink">Polygon Link</label>
                            </div>
                            <div className="input-field">
                                <textarea id="rgbLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                <label htmlFor="rgbLink">RGB Image Link</label>
                            </div>
                            <div className="input-field">
                                <input type="text" id='class' onChange={this.handleChange} />
                                <label htmlFor="class">Class</label>
                            </div>
                            <div className="input-field">
                                <button className="btn indigo accent-1">Add</button>
                            </div>
                        </form>
                    ) : null}
                </div>
            )
        }
        else {
            return (
                <div className="container center">
                    <p>Loading site...</p>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const sites = state.firestore.data.sites;
    const secondarySites = state.firestore.data.secondarySites;
    let site = null;
    let secondary = false;
    const isAdmin = state.auth.isAdmin;
    if (sites && sites[id]) {
        site = sites[id];
    }
    if (secondarySites && secondarySites[id]) {
        site = secondarySites[id];
        secondary = true;
    }
    const rgb = secondary ? state.firestore.data[id + '-s-rgb'] : state.firestore.data[id + '-rgb'];
    // console.log(rgb);
    return {
        site,
        siteId: id,
        rgb,
        auth: state.firebase.auth,
        isAdmin,
        secondary
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        addRGB: (siteId, rgb, secondary) => dispatch(addRGB(siteId, rgb, secondary))
    }
}


export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => [
        {
            collection: 'sites',
            doc: props.match.params.id,
            subcollections: [{ collection: 'rgb' }],
            storeAs: props.match.params.id + '-rgb'
        },
        {
            collection: 'secondarySites',
            doc: props.match.params.id,
            subcollections: [{ collection: 'rgb' }],
            storeAs: props.match.params.id + '-s-rgb'
        }
    ])
)(RgbTable)