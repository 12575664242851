import React, { Component } from 'react'
import { connect } from 'react-redux'
import { signIn } from '../../store/actions/authActions'
import { Redirect } from 'react-router-dom'

import './SignIn.styles.scss'

class SignIn extends Component {
    state = {
        email: '',
        password: ''
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const signinErrorElem = document.getElementById('signin-error');
        signinErrorElem.classList.remove('error');
        signinErrorElem.innerText = '';
        signinErrorElem.classList.add('loading');
        this.props.signIn(this.state);
    }
    // componentDidUpdate(prevProps) {
    //     let signinErrorElem;
    //     if(prevProps.authError !== this.props.authError) {
    //         signinErrorElem = document.getElementById('signin-error');
    //         signinErrorElem.classList.toggle('error');
    //     }
    // }
    render() {
        const {auth} = this.props;
        if (auth.uid) {
            return <Redirect to='/' />;
        }
        return (
            <div className="container">
                <form id="signInForm" onSubmit={this.handleSubmit} className="white z-depth-2">
                    <h5 className="grey-text text-darken-3">Sign In</h5>
                    <div className="input-field">
                        <label htmlFor="email">Email</label>
                        <input type="email" id="email" onChange={this.handleChange} />
                    </div>
                    <div className="input-field">
                        <label htmlFor="password">Password</label>
                        <input type="password" id="password" onChange={this.handleChange} />
                    </div>
                    <div className="input-field login-button">
                        <button className="btn z-depth-0 indigo accent-1">Login</button>
                        <div id="signin-error" className="red-text center" ></div>
                    </div>
                </form>
            </div>
        )
    }
}

// {authError ? <p>{authError}</p> : null}

const mapStateToProps = (state) => {
    return {
        authError: state.auth.authError,
        auth: state.firebase.auth
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        signIn: (credentials) => dispatch(signIn(credentials))
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
