import React from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'


function DetailsTable(props) {
    // let spectraElems = [], hyperElems = [], multiElems = [], rgbElems = [];
    // let emptyElemsArray = [];
    let dateString, removedDayString;
    let allDates = {};
    let dateElems = [];
    const { spectra, hyper, multi, rgb, url } = props;
    const types = ['spectra', 'hyper', 'multi', 'rgb'];
    const allData = [spectra, hyper, multi, rgb];
    // find all the dates
    allData.forEach( (spectraType, index) => {
        for (var objectKey in spectraType) {
            if (spectraType.hasOwnProperty(objectKey)) {
                // Checking if the type is RGB image. it needs to be handled differently.
                if (index === 3) {
                    for (let date in spectraType[objectKey]) {
                        // There is a field in a polygon named 'num'. the other fields are dates.
                        if (date === 'num' || date === 'polygonLink') continue;

                        dateString = spectraType[objectKey][date].date.toDate().toDateString();
                        removedDayString = dateString.slice(dateString.indexOf(" ") + 1);
                        if (!allDates[removedDayString]) {
                            allDates[removedDayString] = { date: spectraType[objectKey][date].date.toDate(), spectra: false, hyper: false, multi: false, rgb: false, kmz: false, meta: false };
                            allDates[removedDayString][types[index]] = true;
                        }
                        else {
                            allDates[removedDayString][types[index]] = true;
                        }
                    }
                }
                else {
                    dateString = spectraType[objectKey].date.toDate().toDateString();
                    removedDayString = dateString.slice(dateString.indexOf(" ") + 1);
                    if (!allDates[removedDayString]) {
                        allDates[removedDayString] = { date: spectraType[objectKey].date.toDate(), spectra: false, hyper: false, multi: false, rgb: false, kmz: false, meta: false };
                        allDates[removedDayString][types[index]] = true;
                    }
                    else {
                        allDates[removedDayString][types[index]] = true;
                    }
                    // Check if image has a meta link
                    if (spectraType[objectKey].metaLink !== '' && spectraType[objectKey].metaLink !== 'https://' ) {
                        allDates[removedDayString].meta = true;
                    }
                    // Check if image has a kmz link
                    if (spectraType[objectKey].kmzLink !== '' && spectraType[objectKey].kmzLink !== 'https://') {
                        allDates[removedDayString].kmz = true;
                    }
                }
            }
        }
    } )
    // console.log(allDates);
    let num = 0;
    let sortedDates = [];
    let spectraElem, multiElem, hyperElem, rgbElem, kmzElem, metaElem;
    for (let shortenedDate in allDates) {
        sortedDates.push([shortenedDate, allDates[shortenedDate].date]);
    }
    // Sort The details table dates
    // Descending sort (from newest to oldest)
    sortedDates.sort(function (a, b) { return b[1].getTime() - a[1].getTime() });
    sortedDates.forEach(specificDateArr => {
        let shortenedDate = specificDateArr[0];
        // console.log(allDates[date]);
        // skip loop if the property is from prototype
        if (allDates.hasOwnProperty(shortenedDate)) {
            spectraElem = allDates[shortenedDate].spectra ? (<td key={`${shortenedDate}-s`}><FontAwesomeIcon icon="check-square" color="black" /></td>) : (<td key={`empty-${num}-s`}></td>);
            multiElem = allDates[shortenedDate].multi ? (<td key={`${shortenedDate}-h`}><FontAwesomeIcon icon="check-square" color="black" /></td>) : (<td key={`empty-${num}-h`}></td>);
            hyperElem = allDates[shortenedDate].hyper ? (<td key={`${shortenedDate}-m`}><FontAwesomeIcon icon="check-square" color="black" /></td>) : (<td key={`empty-${num}-m`}></td>);
            rgbElem = allDates[shortenedDate].rgb ? (<td key={`${shortenedDate}-r`}><FontAwesomeIcon icon="check-square" color="black" /></td>) : (<td key={`empty-${num}-r`}></td>);
            kmzElem = allDates[shortenedDate].kmz ? (<td key={`${shortenedDate}-k`}><FontAwesomeIcon icon="check-square" color="black" /></td>) : (<td key={`empty-${num}-k`}></td>);
            metaElem = allDates[shortenedDate].meta ? (<td key={`${shortenedDate}-meta`}><FontAwesomeIcon icon="check-square" color="black" /></td>) : (<td key={`empty-${num}-meta`}></td>);
            let day = allDates[shortenedDate].date.getUTCDate();
            let month = allDates[shortenedDate].date.getUTCMonth() + 1;
            let year = allDates[shortenedDate].date.getUTCFullYear();
            // let [month, day, year] = allDates[shortenedDate].date.toLocaleDateString().split("/");
            if (day < 10) day = '0' + day;
            if (month < 10) month = '0' + month;
            dateElems.push((
                <tr key={`date-${num}`}>
                    <td key={`dateHeader-${num}`}><Link to={url + `/${year}-${month}-${day}`}>{shortenedDate}</Link></td>
                    {spectraElem}
                    {rgbElem}
                    {multiElem}
                    {hyperElem}
                    {kmzElem}
                    {metaElem}
                </tr>
            ));

            num++;
        }
    });

    return (
        <div>
            <table className="striped highlight responsive-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Spectra</th>
                        <th>RGB<br />Close Range</th>
                        <th>Airborne & Satellite<br />Images</th>
                        <th>Drone & Ultralight<br />Images</th>
                        <th>KMZ Data</th>
                        <th>Meta Data</th>
                    </tr>
                </thead>

                <tbody>
                    {dateElems && dateElems.map(date => date)}
                </tbody>
            </table>
        </div>
    )
}

const mapStateToProps = (state, ownProps) => {
    // console.log(ownProps);
    const siteId = ownProps.siteId;
    const spectra = state.firestore.data[siteId + '-spectras'];
    const hyper = state.firestore.data[siteId + '-hyper'];
    const multi = state.firestore.data[siteId + '-multi'];
    const rgb = state.firestore.data[siteId + '-rgb'];
    return {
        siteId,
        spectra,
        multi,
        hyper,
        rgb
    }
}

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        const secondary = props.secondary ? 'econdaryS' : '';
        return [
            {
                collection: `s${secondary}ites`,
                doc: props.siteId,
                subcollections: [{ collection: 'spectras' }],
                storeAs: props.siteId + '-spectras'
            },
            {
                collection: `s${secondary}ites`,
                doc: props.siteId,
                subcollections: [{ collection: 'hyper' }],
                storeAs: props.siteId + '-hyper'
            },
            {
                collection: `s${secondary}ites`,
                doc: props.siteId,
                subcollections: [{ collection: 'multi' }],
                storeAs: props.siteId + '-multi'
            },
            {
                collection: `s${secondary}ites`,
                doc: props.siteId,
                subcollections: [{ collection: 'rgb' }],
                storeAs: props.siteId + '-rgb'
            }
        ]
    })
)(DetailsTable)