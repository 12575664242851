import React from 'react'
import PdfFile from '../PdfFile/PdfFile.component'

function Gradient() {
    return (
        <div>
            <PdfFile pdfSource="/Gradient.pdf"/>
        </div>
    )
}

export default Gradient
