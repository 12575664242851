import React from 'react'
import PdfFile from '../PdfFile/PdfFile.component'

function Sensors() {
    return (
        <div>
            <PdfFile pdfSource="/Sensors.pdf" />
        </div>
    )
}

export default Sensors
