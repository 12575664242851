import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import DeleteAdditionalInfo from '../DeleteAdditionalInfo/DeleteAdditionalInfo.component'
import PopupImage from '../PopupImage/PopupImage.component'
import './MainTable.styles.scss'

class MainTable extends Component {

    sortSensorDateData = (data) => {
        var sortedDateData = [];
        for (var singleDataName in data) {
            sortedDateData.push([singleDataName, data[singleDataName]]);
        };

        sortedDateData.sort((dateDataA, dateDataB) => {
            return dateDataA[1][1].toUpperCase().localeCompare(dateDataB[1][1].toUpperCase());
        });

        return sortedDateData;
    }

    render() {
        // const dateString = this.props.date.toDateString();
        // const removedDayString = dateString.slice(dateString.indexOf(" ") + 1);
        const {date, site, siteId, dateData, auth, isAdmin, secondary} = this.props;
        let sensorElements = [];
        let specificRowData = {};
        let i = 0;
        if(site) {
            for (var sensorName in dateData) {
                if (dateData.hasOwnProperty(sensorName) && dateData[sensorName]) {
                    const { num, ...otherDateData } = dateData[sensorName];
                    const sortedDateData = this.sortSensorDateData(otherDateData);
                    for(var index = 0; index < sortedDateData.length; index++) {
                        if(sortedDateData[index]) {
                            specificRowData = {
                                instrument: sensorName,
                                fileLink: sortedDateData[index][1][0]
                            };
                            sensorElements.push((
                                <tr key={sortedDateData[index][0]}>
                                    {i ? <td></td> : <td>{sensorName}</td>}
                                    <td>
                                        <div className="date-data-extra">
                                            {sortedDateData[index][1][3] ?
                                                <a href={sortedDateData[index][1][0]} target="_blank" rel="noopener noreferrer">{sortedDateData[index][1][1]}</a>
                                                : (auth.uid ?
                                                    <a href={sortedDateData[index][1][0]} target="_blank" rel="noopener noreferrer">{sortedDateData[index][1][1]}</a>
                                                    : <PopupImage imageUrl={sortedDateData[index][1][9]} name={sortedDateData[index][1][1]} tableClass="main-table" />)}
                                            {/* {" "}
                                            {sortedDateData[index][1][3] ? <span className="new badge blue" data-badge-caption="extra data"></span> : null} */}
                                        </div>
                                    </td>
                                    <td>
                                        {sortedDateData[index][1][4] ?
                                            <a href={sortedDateData[index][1][2]} target="_blank" rel="noopener noreferrer">{sortedDateData[index][1][8]}</a>
                                            : sortedDateData[index][1][8]}
                                    </td>
                                    {isAdmin && sortedDateData[index][1][3] ? <DeleteAdditionalInfo siteId={siteId} stringDate={date} data={specificRowData} secondary={secondary} /> :
                                        isAdmin ? <td></td> : null}
                                </tr>
                            ));
                            if (!sortedDateData[index][1][3] && sortedDateData[index][1][5] !== '' && sortedDateData[index][1][5] !== 'https://') {
                                sensorElements.push((
                                    <tr key={`${sortedDateData[index][0]}-meta`}>
                                        <td></td>
                                        <td>
                                            <div className="date-data-meta">
                                                <a href={sortedDateData[index][1][5]} target="_blank" rel="noopener noreferrer">{sortedDateData[index][1][6]}</a>
                                                {/* {" "}<span className="new badge blue" data-badge-caption="meta info"></span> */}
                                            </div>
                                        </td>
                                        <td>{sortedDateData[index][1][7]}</td>
                                        {isAdmin ? <td></td> : null}
                                    </tr>
                                ));
                            }
                            i++;
                        }
                    }
                    // for (var singleDataName in dateData[sensorName]) {
                    //     if (singleDataName !== "num" && dateData[sensorName].hasOwnProperty(singleDataName) && dateData[sensorName][singleDataName]) {
                    //         specificRowData = {
                    //             instrument: sensorName,
                    //             fileLink: dateData[sensorName][singleDataName][0]
                    //         };
                    //         sensorElements.push((
                    //             <tr key={singleDataName}>
                    //                 {i ? <td></td> : <td>{sensorName}</td> }
                    //                 <td>
                    //                     <div className="date-data-extra">
                    //                         {dateData[sensorName][singleDataName][3] ? 
                    //                             <a href={dateData[sensorName][singleDataName][0]} target="_blank" rel="noopener noreferrer">{dateData[sensorName][singleDataName][1]}</a>
                    //                             : (auth.uid ? 
                    //                                 <a href={dateData[sensorName][singleDataName][0]} target="_blank" rel="noopener noreferrer">{dateData[sensorName][singleDataName][1]}</a>
                    //                                 : <PopupImage imageUrl={dateData[sensorName][singleDataName][9]} name="" tableClass="main-table"/>)}
                    //                         {/* {" "}
                    //                         {dateData[sensorName][singleDataName][3] ? <span className="new badge blue" data-badge-caption="extra data"></span> : null} */}
                    //                     </div>
                    //                 </td>
                    //                 <td>
                    //                     {dateData[sensorName][singleDataName][4] ? 
                    //                         <a href={dateData[sensorName][singleDataName][2]} target="_blank" rel="noopener noreferrer">{dateData[sensorName][singleDataName][8]}</a>
                    //                         : dateData[sensorName][singleDataName][2]}
                    //                 </td>
                    //                 {isAdmin && dateData[sensorName][singleDataName][3] ? <DeleteAdditionalInfo siteId={siteId} stringDate={date} data={specificRowData} /> : 
                    //                     isAdmin ? <td></td> : null}
                    //             </tr>
                    //         ));
                    //         if (!dateData[sensorName][singleDataName][3] && dateData[sensorName][singleDataName][5] !== '' && dateData[sensorName][singleDataName][5] !== 'https://') {
                    //             sensorElements.push((
                    //                 <tr key={`${singleDataName}-meta`}>
                    //                     <td></td>
                    //                     <td>
                    //                         <div className="date-data-meta">
                    //                             <a href={dateData[sensorName][singleDataName][5]} target="_blank" rel="noopener noreferrer">{dateData[sensorName][singleDataName][6]}</a>
                    //                             {/* {" "}<span className="new badge blue" data-badge-caption="meta info"></span> */}
                    //                         </div>
                    //                     </td>
                    //                     <td>{dateData[sensorName][singleDataName][7]}</td>
                    //                     {isAdmin ? <td></td> : null}
                    //                 </tr>
                    //             ));
                    //         }
                    //         i++;
                    //     }
                    // }
                    i = 0;
                }
            }
            return (
                <div className="main-table white">
                    <h4>{date} data from site: {site ? site.title : null}</h4>
                    <table className="striped highlight responsive-table">
                        <thead>
                            <tr>
                                <th>SENSOR/INSTRUMENTS</th>
                                <th>AVAILABLE DATA</th>
                                <th>INFO TEXT & LINK TO MEASUREMENT DESCRIPTION</th>
                                {(auth.uid && isAdmin) ? <th>DELETE DATA</th> : null}
                            </tr>
                        </thead>

                        <tbody>
                            { sensorElements && sensorElements.map( dataRow => dataRow ) }
                        </tbody>
                    </table>
                </div>
            )
        }
        else {
            return (
                <div className="container center">
                    <p>Loading site...</p>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    // const date = new Date(ownProps.match.params.date);
    const date = ownProps.match.params.date;
    const siteId = ownProps.match.params.id;
    const dateData = state.firestore.data[siteId + `-${date}`];
    const sites = state.firestore.data.sites;
    const secondarySites = state.firestore.data.secondarySites;
    let site = null;
    let secondary = false;
    if (sites && sites[siteId]) {
        site = sites[siteId];
    }
    if (secondarySites && secondarySites[siteId]) {
        site = secondarySites[siteId];
        secondary = true;
    }
    const isAdmin = state.auth.isAdmin;
    return {
        date,
        siteId,
        site,
        dateData,
        auth: state.firebase.auth,
        isAdmin,
        secondary
    }
}


export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        const secondary = props.secondary ? 'econdaryS' : '';
        return [
            {
                collection: `s${secondary}ites`,
                doc: props.match.params.id,
                subcollections: [{ collection: props.match.params.date }],
                storeAs: props.match.params.id + `-${props.match.params.date}`
            }
        ]
    })
)(MainTable)