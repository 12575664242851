import React from 'react'
import { NavLink, withRouter } from 'react-router-dom'
import { connect } from "react-redux"
import { signOut } from '../../store/actions/authActions'
import { compose } from 'redux';

function UserDropdown(props) {
    const { auth, signOut, profile } = props;
    const signedInMenu = (
        <ul id="user-options" className="dropdown-content">
            <li><NavLink to="/profile"><span>{`${profile.firstName}'s Profile`}</span></NavLink></li>
            <li><NavLink to={"/add-site"}><span>Add Site</span></NavLink></li>
            <li onClick={signOut}><NavLink to={props.location.pathname}><span>Log Out</span></NavLink></li>
        </ul>);
    const signedOutMenu = (
        <ul id="user-options" className="dropdown-content">
            <li><NavLink to="/signup"><span>Sign Up</span></NavLink></li>
            <li><NavLink to="/signin"><span>Login</span></NavLink></li>
        </ul>);
    const links = auth.uid ? signedInMenu : signedOutMenu;
    return (
        <div>
            {links}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        auth: state.firebase.auth
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        signOut: () => dispatch(signOut())
    }
}

export default compose(
    withRouter,
    connect(mapStateToProps, mapDispatchToProps)
)(UserDropdown);