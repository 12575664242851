import React, { Component } from 'react'
import { connect } from 'react-redux'
import { firestoreConnect } from 'react-redux-firebase'
import { compose } from 'redux'
import { addMulti } from '../../store/actions/siteActions'
import PopupImage from './PopupImage/PopupImage.component'
import DeleteButton from './DeleteButton/DeleteButton.component'
import Map from '../dashboard/Map'
import * as URI from 'uri-js'
import './tables.scss'

class MultiTable extends Component {
    state = {
        date: '',
        sensor: '',
        boundingPolygon: '',
        name: '',
        fileLink: '',
        imageLink: '',
        metaName: '',
        metaLink: '',
        isMetaLink: false,
        kmzName: '',
        kmzLink: '',
        kmzDescription: '',
        multiExtraInfo: ''
    }
    checkUrls = (urlArray) => {
        return urlArray.map(url => {
            if (URI.parse(url).scheme == null) {
                return 'https://' + url;
            }
            return url;
        })
    }
    handleChange = (e) => {
        this.setState({
            [e.target.id]: e.target.value
        });
    }
    handleSubmit = (e) => {
        e.preventDefault();
        let {isMetaLink, ...multi} = this.state;
        const { secondary } = this.props;

        let checkboxChecked = document.getElementById("extra-data").checked;

        const urlsToCheck = isMetaLink ? [multi.fileLink, multi.imageLink, multi.kmzLink, multi.metaLink] :
            [multi.fileLink, multi.imageLink, multi.kmzLink];
        const fixedURLS = this.checkUrls(urlsToCheck);
        multi.fileLink = fixedURLS[0];
        multi.imageLink = fixedURLS[1];
        multi.kmzLink = fixedURLS[2];
        if (isMetaLink) {
            multi.metaLink = fixedURLS[3];
        }

        multi.date = multi.date.slice(0, 4) + '-' + multi.date.slice(4, 6) + '-' + multi.date.slice(6);
        this.props.addMulti(this.props.siteId, multi, checkboxChecked, isMetaLink, secondary);
        let multiOnlyElems = document.getElementsByClassName("multi-only");
        let extraInfoElem = document.getElementById("multi-extra-info");
        for (let i = 0; i < multiOnlyElems.length; i++) {
            multiOnlyElems[i].style.display = "block";
        }
        extraInfoElem.style.display = "none";

        e.target.reset();
    }
    toggleExtraData = () => {
        let checkboxChecked = document.getElementById("extra-data").checked;
        let multiOnlyElems = document.getElementsByClassName("multi-only");
        let extraInfoElem = document.getElementById("multi-extra-info");
        for (let i = 0; i < multiOnlyElems.length; i++) {
            if (checkboxChecked) {
                multiOnlyElems[i].style.display = "none";
                extraInfoElem.style.display = "block";
            }
            else {
                multiOnlyElems[i].style.display = "block";
                extraInfoElem.style.display = "none";
            }
        }
    }
    toggleMetaLink = () => {
        this.setState({
            ...this.state,
            isMetaLink: !this.state.isMetaLink
        })
    }

    isURL = (str) => {
        const pattern = new RegExp('^(https:\\/\\/)'); // fragment locator
        return !!pattern.test(str);
    }

    render() {
        const { auth, site, siteId, multi, isAdmin, secondary } = this.props;
        let dateString, removedDayString;
        // if (!auth.uid) return <Redirect to='/signin' />;
        if (site) {
            var multiElems = [];
            for (var multiKey in multi) {
                if (multi.hasOwnProperty(multiKey) && multi[multiKey]) {
                    dateString = multi[multiKey].date.toDate().toDateString();
                    removedDayString = dateString.slice(dateString.indexOf(" ") + 1);
                    const metaData = multi[multiKey].metaLink;
                    if (auth.uid) {
                        multiElems.push((
                            <tr key={multiKey}>
                                <td>{removedDayString}</td>
                                <td>{multi[multiKey].sensor}</td>
                                <td>{multi[multiKey].fileLink !== 'https://' ? <a href={multi[multiKey].fileLink} target="_blank" rel="noopener noreferrer">{multi[multiKey].name}</a> : '- - -'}</td>
                                <td>{multi[multiKey].boundingPolygon}</td>
                                <td>{this.isURL(metaData) ? <a href={multi[multiKey].metaLink} target="_blank" rel="noopener noreferrer">Meta Link</a> : '- - -'}</td>
                                <td>{multi[multiKey].kmzLink !== 'https://' ? <a href={multi[multiKey].kmzLink} target="_blank" rel="noopener noreferrer">KMZ Link</a> : '- - -'}</td>
                                { isAdmin ? <DeleteButton siteId={siteId} id={multiKey} data={multi[multiKey]} dataType="multi" secondary={secondary} /> : null}
                            </tr>
                        ));
                    }
                    else {
                        multiElems.push((
                            <tr key={multiKey}>
                                <td>{removedDayString}</td>
                                <td>{multi[multiKey].sensor}</td>
                                <td><PopupImage imageUrl={multi[multiKey].imageLink} name={multi[multiKey].name} tableClass="multi-table" /></td>
                                <td>{multi[multiKey].boundingPolygon}</td>
                                <td>{this.isURL(metaData) ? <a href={multi[multiKey].metaLink} target="_blank" rel="noopener noreferrer">Meta Link</a> : '- - -'}</td>
                                <td>{multi[multiKey].kmzLink !== 'https://' ? <a href={multi[multiKey].kmzLink} target="_blank" rel="noopener noreferrer">KMZ Link</a> : '- - -'}</td>
                            </tr>
                        ));
                    }
                }
            }
            return (
                <div className="multi-table row">
                    <div className="col s12 m9 multi-table-inside">
                        <div className="white data-table">
                            <h4>{site ? site.title : null} / Airborne & Satellite Images</h4>
                            <table className="striped highlight responsive-table">
                                <thead>
                                    <tr>
                                    <th>Date</th>
                                    <th>Sensor/Camera</th>
                                    <th>File Link</th>
                                    <th>Bounding<br/>Polygon</th>
                                    <th>Meta Link</th>
                                    <th>KMZ Link</th>
                                    {(auth.uid  && isAdmin) ? <th>Delete Multi-Spectra</th> : null}
                                    </tr>
                                </thead>

                                <tbody>
                                    {multiElems && multiElems.map(multi => {
                                        return multi;
                                    })}
                                </tbody>
                            </table>
                            {auth.uid && isAdmin ? (
                                <form className="white z-depth-2" onSubmit={this.handleSubmit}>
                                    <h5 className="grey-text text-darken-3">Add a new Multi-Spectra:</h5>
                                    <div className="input-field">
                                        <input type="text" id='date' onChange={this.handleChange} />
                                        <label htmlFor="date">Date</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id='sensor' onChange={this.handleChange} />
                                        <label htmlFor="sensor">Sensor</label>
                                    </div>
                                    <div className="input-field multi-only">
                                        <input type="text" id='boundingPolygon' onChange={this.handleChange} />
                                        <label htmlFor="boundingPolygon">Bounding Polygon</label>
                                    </div>
                                    <div className="input-field">
                                        <input type="text" id='name' onChange={this.handleChange} />
                                        <label htmlFor="name">File Name</label>
                                    </div>
                                    <div className="input-field">
                                        <textarea id="fileLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="fileLink">File Link</label>
                                    </div>
                                    <div className="input-field multi-only">
                                        <textarea id="imageLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="imageLink">Quick-Look Image Link (Direct link to image)</label>
                                    </div>
                                    <div className="row">
                                        <div className="input-field multi-only col s6">
                                            <textarea id="metaName" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="metaName">Meta {this.state.isMetaLink ? "Link Name" : "Information"}</label>
                                        </div>
                                        {this.state.isMetaLink ? (
                                            <div className="input-field multi-only col s6">
                                                <textarea id="metaLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                                <label htmlFor="metaLink">Meta Link</label>
                                            </div>
                                        ) : null}
                                        <div className="multi-only col s6">
                                            <label htmlFor="isMetaLink">
                                                <div className="row"></div>
                                                <div className="row"></div>
                                                <div className="row">
                                                    <input type="checkbox" id="isMetaLink" onClick={this.toggleMetaLink} />
                                                    <span>Is Meta Information a link?</span>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="input-field multi-only col s4">
                                            <textarea id="kmzName" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzName">KMZ Link Name</label>
                                        </div>
                                        <div className="input-field multi-only col s4">
                                            <textarea id="kmzLink" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzLink">KMZ Link</label>
                                        </div>
                                        <div className="input-field multi-only col s4">
                                            <textarea id="kmzDescription" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                            <label htmlFor="kmzDescription">KMZ Description</label>
                                        </div>
                                    </div>
                                    <div className="input-field" id="multi-extra-info" style={{ display: "none" }}>
                                        <textarea id="multiExtraInfo" className="materialize-textarea" onChange={this.handleChange}></textarea>
                                        <label htmlFor="multiExtraInfo">Info Text</label>
                                    </div>
                                    <label htmlFor="extra-data">
                                        <input type="checkbox" id="extra-data" onClick={this.toggleExtraData} />
                                        <span>Additional data for specific date (Not a multi-spectra)</span>
                                    </label>
                                    <div className="input-field">
                                        <button className="btn indigo accent-1">Add</button>
                                    </div>
                                </form>
                            ) : null}
                        </div>
                    </div>
                    <div className="col s12 m3 side-map">
                        <Map />
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="container center">
                    <p>Loading site...</p>
                </div>
            )
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    const id = ownProps.match.params.id;
    const sites = state.firestore.data.sites;
    const secondarySites = state.firestore.data.secondarySites;
    let site = null;
    let secondary = false;
    const multi = state.firestore.data[id + '-multi'];
    const isAdmin = state.auth.isAdmin;
    if (sites && sites[id]) {
        site = sites[id];
    }
    if (secondarySites && secondarySites[id]) {
        site = secondarySites[id];
        secondary = true;
    }
    return {
        siteId: id,
        site: site,
        multi: multi,
        auth: state.firebase.auth,
        isAdmin,
        secondary
    }
}

const mapDispatchToProps = dispatch => {
    return {
        // The site id is inside the spectra variable.
        addMulti: (siteId, multi, isExtraData, isMetaLink, secondary) => dispatch(addMulti(siteId, multi, isExtraData, isMetaLink, secondary))
    }
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    firestoreConnect((props) => {
        const secondary = props.secondary ? 'econdaryS' : '';
        return [
            {
                collection: `s${secondary}ites`,
                doc: props.match.params.id,
                subcollections: [{ collection: 'multi' }],
                storeAs: props.match.params.id + '-multi',
                orderBy: [['date', 'desc'], ['sensor', 'asc'], ['name', 'asc']]
            }
        ]
    })
)(MultiTable)