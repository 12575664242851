const initState = {
    sites: [],
    secondarySites: [],
    chosenSite: 'Continuous Gradient'
}

const siteReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CREATE_SITE':
            console.log('Created site', action.site);
            return state;
        case 'CREATE_SITE_ERROR':
            console.log('Create site error', action.err);
            return state;
        case 'ADD_SPECTRA':
            console.log('Added Spectra', action.spectra);
            return state;
        case 'ADD_SPECTRA_ERROR':
            console.log('Spectra Adding error', action.err);
            return state;
        case 'ADD_MULTI':
            console.log('Added Multi-Spectra', action.multi);
            return state;
        case 'ADD_MULTI_ERROR':
            console.log('Multi-Spectra Adding error', action.err);
            return state;
        case 'ADD_HYPER':
            console.log('Added Hyper-Spectra', action.hyper);
            return state;
        case 'ADD_HYPER_ERROR':
            console.log('Hyper-Spectra Adding error', action.err);
            return state;
        case 'DELETE_DATA':
            console.log('Deleted Data', action.datatype);
            return state;
        case 'DELETE_DATA_ERROR':
            console.log('Deleting Data Error', action.err);
            return state;
        case 'CHOSE_SITE':
            console.log('Chose site', action.chosenSite);
            return {
                ...state,
                chosenSite: action.chosenSite
            }
        default:
            return state;
    }
}

export default siteReducer