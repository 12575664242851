import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/auth';

export const makeAdmin = (adminEmail) => {
    return (dispatch, getState) => {
        const addAdminRole = firebase.functions().httpsCallable('addAdminRole');
        addAdminRole({ email: adminEmail }).then(result => {
            dispatch({ type: 'ADDADMIN_SUCCESS', result })
        }).catch(err => {
            dispatch({ type: 'ADDADMIN_ERROR', err })
        });
    }
}

export const checkIfAdmin = (userId) => {
    return (dispatch, getState) => {
        if(!firebase.auth().currentUser) {
            // if the user isn't logged in dispatch IS_ADMIN_FAILURE
            dispatch({ type: 'IS_ADMIN_FAILURE', isAdmin: false });
        }
        else {
            firebase.auth().currentUser.getIdTokenResult()
                .then((idTokenResult) => {
                    const isAdmin = !!idTokenResult.claims.admin
                    if (isAdmin) {
                        dispatch({ type: 'IS_ADMIN_SUCCESS', isAdmin })
                    } else {
                        // Show regular user UI.
                        dispatch({ type: 'IS_ADMIN_FAILURE', isAdmin })
                    }
                })
                .catch((err) => {
                    dispatch({ type: 'IS_ADMIN_ERROR', err })
                });
        }
    }
}

// firebase.auth().onAuthStateChanged((user) => {
//     if (user) {
//         user.getIdTokenResult().then(idTokenResult => {
//             if (idTokenResult.claims.admin) {
//                 dispatch({ type: 'IS_ADMIN_SUCCESS' })
//             }
//             else {
//                 dispatch({ type: 'IS_ADMIN_FAILURE' })
//             }
//         })
//     }
//     else {
//         dispatch({ type: 'IS_ADMIN_FAILURE' })
//     }

// })