import React, { Component } from 'react';
import "./Map.scss";
// import L from 'leaflet';
import { connect } from 'react-redux';
import labLogo from './logoLab.JPG';
import Amatzia from './sitesImages/Amatzia.png';
import Avisur from './sitesImages/Avisur.png';
import Dvira from './sitesImages/Dvira.png';
import Lehavim from './sitesImages/Lehavim.png';
import ContinuousGradient from './sitesImages/Continuous_gradient.png';
import Lachish from './sitesImages/Lachish.png';
import Zecharia from './sitesImages/Zecharia.png';

class Map extends Component {
    componentDidMount() {
        // var myMap = L.map('mapid').setView([31.5061, 34.87872222222222], 10);
        // L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        //     attribution: 'Map data & copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
        // }).addTo(myMap);
        // var Avisur = L.marker([31.651117, 34.919998]);
        // var Amatzia = L.marker([31.505802777777777, 34.88278611111111]);
        // var Dvira = L.marker([31.428977777777778, 34.85845555555556]);
        // var Lehavim = L.marker([31.360977777777777, 34.838208333333334]);
        // var TelLachis = L.marker([31.565224, 34.848550]);
        // var Zecharia = L.marker([31.709696, 34.944924]);
        // Dvira.bindPopup("Dvira");
        // Amatzia.bindPopup("Amatzia");
        // Lehavim.bindPopup("Lehavim");
        // Avisur.bindPopup("Avisur");
        // TelLachis.bindPopup("Tel Lachish");
        // Zecharia.bindPopup("Zecharia");

        let chosenMarker;
        if(this.props.chosenSite !== '') {
            this.setState({
                ...this.state,
                // myMap,
                // mapMarkers: { Avisur, Amatzia, Dvira, Lehavim, TelLachis, Zecharia }
            }, () => {
                switch (this.props.chosenSite) {
                    case "Avisur Highland":
                        // Avisur.addTo(this.state.myMap);
                        // chosenMarker = Avisur;
                        break;
                    case "Amatzia":
                        // Amatzia.addTo(this.state.myMap);
                        // chosenMarker = Amatzia;
                        break;
                    case "Dvira":
                        // Dvira.addTo(this.state.myMap);
                        // chosenMarker = Dvira
                        break;
                    case "Lehavim":
                        // Lehavim.addTo(this.state.myMap);
                        // chosenMarker = Lehavim;
                        break;
                    case "Tel Lachis":
                        // TelLachis.addTo(this.state.myMap);
                        // chosenMarker = TelLachis;
                        break;
                    case "Zecharia":
                        // Zecharia.addTo(this.state.myMap);
                        // chosenMarker = Zecharia;
                        break;
                    default: break;
                }
                if(chosenMarker) {
                    this.setState({
                        ...this.state,
                        chosenMarker
                    })
                }
            })
        }
        else {
            this.setState({
                ...this.state,
                // myMap,
                // mapMarkers: { Avisur, Amatzia, Dvira, Lehavim, TelLachis, Zecharia },
                chosenMarker: null
            })
        }
    }
    componentDidUpdate(prevProps) {
        if(prevProps.chosenSite !== this.props.chosenSite){
            let { chosenSite } = this.props;
            // let { mapMarkers: { Avisur, Amatzia, Dvira, Lehavim, TelLachis, Zecharia }, chosenMarker } = this.state;
            // if (chosenMarker) {
                // chosenMarker.remove();
            // }
            switch (chosenSite) {
                case "Avisur Highland":
                    // Avisur.addTo(this.state.myMap);
                    // chosenMarker = Avisur;
                    break;
                case "Amatzia":
                    // Amatzia.addTo(this.state.myMap);
                    // chosenMarker = Amatzia;
                    break;
                case "Dvira":
                    // Dvira.addTo(this.state.myMap);
                    // chosenMarker = Dvira
                    break;
                case "Lehavim":
                    // Lehavim.addTo(this.state.myMap);
                    // chosenMarker = Lehavim;
                    break;
                case "Tel Lachis":
                    // TelLachis.addTo(this.state.myMap);
                    // chosenMarker = TelLachis;
                    break;
                case "Zecharia":
                    // Zecharia.addTo(this.state.myMap);
                    // chosenMarker = Zecharia;
                    break;
                default: break;
            }

            // this.state.myMap.flyTo(chosenMarker.getLatLng(), 12);
            // this.setState({
            //     ...this.state,
            //     // chosenMarker
            // });
        // var polygon1 = L.polygon([
        //     [31.4165, 34.8323],
        //     [31.4125, 34.8307],
        //     [31.4119, 34.8336]
        // ]).addTo(myMap);
        // polygon1.bindPopup("I am a polygon.");
        }
    }
    render() {
        const { chosenSite } = this.props;
        const siteImagesDict = {
            "Avisur Highland": Avisur,
            "Amatzia": Amatzia,
            Dvira,
            Lehavim,
            Zecharia,
            "Tel Lachish": Lachish,
            "Continuous Gradient": ContinuousGradient
        }

        return (
            <div className="row map">
                <div className="col s12" id="mapid">
                    <img src={siteImagesDict[chosenSite]} alt="Chosen Site"/>
                </div>
                <div className="col s12">
                    <div className="card-panel">
                        <span className="card-panel-title">Intelligent HyperSpectral and GeoSensing Laboratory</span><br/>
                        <div className="card-footer">
                            <b>LTGTN was built with the generous support of Norman and Helen Asher Space Research Institute (ASRI), Technion, Israel Institute of Technology</b>
                            <img src={labLogo} alt="" width="32" height="32" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        chosenSite: state.site.chosenSite
    }
}


export default connect(mapStateToProps)(Map)