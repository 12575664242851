import React, { Component } from 'react'
import Draggable from 'react-draggable'
import $ from 'jquery'
import './PopupImage.styles.scss'

class PopupImage extends Component {
    state = {
        showPopup: false,
        actualImageURL: '',
        actualImageWidth: 0,
        deltaPosition: {
            x: 0, y: 0
        },
        initialDiffX: 0
    }

    handleToggle = () => {
        const { showPopup } = this.state;
        this.setState({ 
            showPopup: !showPopup,
            deltaPosition: showPopup ? { x: 0, y: 0 } : this.state.deltaPosition
        });
    };

    componentDidMount() {
        const {tableClass} = this.props;
        this.setState({
            initialDiffX: $(".popup").position().left - $(`.${tableClass}`).position().left
        });
        this._isMounted = true;
        let reactDraggable = document.getElementsByClassName('react-draggable');
        if(this.props.tableClass !== "main-table") {
            let componentElem = document.getElementsByClassName(this.props.tableClass)[0];
            componentElem.style.position = 'relative';
            componentElem.style['z-index'] = '0';
            
            let tableElem = document.getElementsByClassName(`${this.props.tableClass}-inside`)[0];
            tableElem.style.position = 'relative';
            tableElem.style['z-index'] = '2';
            if (this.props.tableClass !== "rgb-table") {
                let mapWrapper = document.getElementsByClassName('side-map')[0];
                mapWrapper.style.position = 'relative';
                mapWrapper.style['z-index'] = '1';
            }
        }
        for(let i=0; i<reactDraggable.length; i++) {
            reactDraggable[i].style.position = "relative";
            reactDraggable[i].style["z-index"] = "2";
        }
    }

    componentDidUpdate() {
        const imgSrc = this.props.imageUrl;
        if (imgSrc !== '') {
            const img = new Image();
            img.src = imgSrc;
            img.onload = () => {
                if(this._isMounted) {
                    this.setState({
                        actualImageWidth: img.width
                    });
                }
            };
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleDrag = (e, ui) => {
        const { x, y } = this.state.deltaPosition;
        this.setState({
            deltaPosition: {
                x: x + ui.deltaX,
                y: y + ui.deltaY,
            }
        });
    };

    render() {
        const { showPopup, initialDiffX, actualImageWidth } = this.state;
        const { name, imageUrl } = this.props;
        
        if (imageUrl !== '') {
            return (
                <div className="popup" onClick={showPopup ? null : this.handleToggle} >
                    <span className="press-link">{name === "" ? null : `${name} `}</span>
                    <Draggable handle=".handle" onDrag={this.handleDrag} bounds={{ left: initialDiffX + 300 > 0 ? -Math.floor((initialDiffX + 300)) : 0 }}
                        position={ this.state.deltaPosition } >
                        <div>
                            <div className={showPopup ? "handle show" : "handle"}>Drag from here</div>
                            <img src={imageUrl} alt="Example" id="myPopup" className={showPopup ? "popup-image show" : "popup-image"} style={{marginLeft: `-${Math.round(actualImageWidth/2)+20}px`}} />
                            <i className={showPopup ? "material-icons close-image show" : "material-icons close-image"} onClick={showPopup ? this.handleToggle : null} style={{ marginLeft: `${Math.round(actualImageWidth / 2) - 50}px` }}>
                                close
                            </i>
                        </div>
                    </Draggable>
                </div>
            )
        }
        else {
            return (
                <div className="popup" >
                    <span className="press-link">Quick Look Loading...</span>
                </div>
            )
        }
    }
}

// onMouseOver={this.handleToggle} onMouseOut={this.handleToggle}

export default PopupImage