import React, { Component } from 'react'
import './RgbModal.styles.scss'

export default class RgbModal extends Component {
    state = {
        actualImageUrl: '',
    }

    // componentDidMount() {
    //     let { imageUrl } = this.props;
    //     if (imageUrl !== '' && imageUrl !== 'https://') {
    //         this._isMounted = true;
    //         fetch('https://ltgtn-backend.ew.r.appspot.com/getdata', {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json'
    //             },
    //             body: JSON.stringify({
    //                 fileUrl: imageUrl
    //             })
    //         })
    //             .then(res => res.json())
    //             .then(thumbnailData => {
    //                 let buf = new Uint8Array(thumbnailData.fileBinary.data);
    //                 let blob = new Blob([buf], { type: "image/jpeg" });
    //                 console.log(URL.createObjectURL(blob));
    //                 if (this._isMounted) {
    //                     this.setState({
    //                         ...this.state,
    //                         actualImageURL: URL.createObjectURL(blob)
    //                     });
    //                 }
    //             });
    //         // .catch(err => {
    //         //     console.log(err);
    //         // })
    //     }
    // }

    // componentWillUnmount() {
    //     this._isMounted = false;
    // }

    render() {
        const { imageUrl } = this.props;
        return (
            <div className="rgb-modal">
                <button data-target={this.props.rgbKey} className="btn modal-trigger">Quick-Look</button>
                <div id={this.props.rgbKey} className="modal">
                    <div className="modal-content">
                        {/* <h4>Modal Header</h4>
                        <p>A bunch of text</p> */}
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <img src={imageUrl} alt="Example" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

